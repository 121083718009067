import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import {
  addDoc,
  collection,
  serverTimestamp,
  getDoc,
  doc,
} from 'firebase/firestore';

function ContactModal({ service, request, onSubmit, onClose }) {
  const { currentUser } = useAuth();
  const [message, setMessage] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [sending, setSending] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    try {
      // First create or get the message thread
      const threadData = {
        participants: [currentUser.uid, service.userId],
        serviceName: service.title,
        serviceId: service.id,
        createdAt: serverTimestamp(),
        lastMessageAt: serverTimestamp(),
        lastMessage: message,
        lastSenderId: currentUser.uid,
        read: false,
      };

      // Create the thread
      const threadRef = await addDoc(collection(db, 'messages'), threadData);

      // Add the first message to the thread
      await addDoc(collection(db, 'messages'), {
        threadId: threadRef.id,
        message: message,
        senderId: currentUser.uid,
        receiverId: service.userId,
        createdAt: serverTimestamp(),
        read: false,
        participants: [currentUser.uid, service.userId],
        isThreadMessage: true,
      });

      // Create service request
      await addDoc(collection(db, 'serviceRequests'), {
        serviceId: service.id,
        serviceName: service.title,
        sellerId: service.userId,
        buyerId: currentUser.uid,
        message: message,
        status: 'pending',
        createdAt: serverTimestamp(),
        threadId: threadRef.id, // Link to message thread
      });

      onSubmit(message);
      onClose();
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  const item = service || request;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <form onSubmit={handleSubmit}>
          <div className="modal-header">
            <h2>Contact {service ? 'Service Provider' : 'Buyer'}</h2>
            <button type="button" className="close-btn" onClick={onClose}>
              <FaTimes />
            </button>
          </div>

          <div className="modal-body">
            <div className="service-details">
              <h3>{item.title}</h3>
              {service ? (
                <p className="price">
                  {service.pricingType === 'on_request'
                    ? 'Price on request'
                    : `$${service.price}${
                        service.pricingType === 'starting_from'
                          ? ' (Starting from)'
                          : ''
                      }`}
                </p>
              ) : (
                <p className="price">Budget: ${request.budget}</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                rows={4}
                placeholder={
                  service
                    ? 'Describe your requirements...'
                    : 'Describe how you can help...'
                }
              />
            </div>

            <div className="form-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                  required
                />
                <span>
                  I agree to OECSL&apos;s{' '}
                  <span
                    className="terms-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTerms(true);
                    }}
                  >
                    terms and conditions
                  </span>{' '}
                  for marketplace transactions / communication
                </span>
              </label>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={sending || !agreed || !message.trim()}
            >
              {sending ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>
      </div>

      {showTerms && (
        <div className="modal-overlay">
          <div className="terms-modal">
            <h2>Terms and Conditions</h2>
            <div className="terms-content">
              <h3>1. Introduction</h3>
              <p>
                These Terms and Conditions govern your use of the OECSL
                marketplace platform. By using our platform, you agree to these
                terms in full.
              </p>

              <h3>2. Key Definitions</h3>
              <ul>
                <li>&quot;Platform&quot; - The OECSL marketplace</li>
                <li>&quot;User&quot; - Any registered member</li>
                <li>&quot;Service Provider&quot; - Users offering services</li>
                <li>&quot;Buyer&quot; - Users purchasing services</li>
                <li>
                  &quot;Transaction&quot; - Any exchange of services between
                  users
                </li>
              </ul>

              <h3>3. Platform Role & Liability</h3>
              <p>
                OECSL serves solely as a platform connecting service providers
                and buyers. The platform is not directly involved in
                transactions between users.
              </p>

              <p className="note">
                <strong>Important Note:</strong> OECSL does not:
              </p>
              <ul>
                <li>Participate in any transaction between users</li>
                <li>Guarantee service quality, safety, or legality</li>
                <li>Verify user information accuracy</li>
                <li>Accept responsibility for user disputes</li>
                <li>Bear liability for losses or damages from platform use</li>
              </ul>

              <h3>4. User Obligations</h3>
              <div className="obligations-section">
                <div className="obligation-group">
                  <h4>General Requirements:</h4>
                  <ul>
                    <li>Provide accurate information</li>
                    <li>Maintain professional communication</li>
                    <li>Report violations and suspicious activities</li>
                  </ul>
                </div>

                <div className="obligation-group">
                  <h4>Prohibited Actions:</h4>
                  <ul>
                    <li>Fraudulent activities</li>
                    <li>Platform misuse</li>
                    <li>Harassment or abuse</li>
                    <li>Sharing personal contact information</li>
                  </ul>
                </div>
              </div>

              <h3>5. Service Provider Responsibilities</h3>
              <p>
                Service providers are expected to maintain high professional
                standards and clear communication throughout service delivery.
              </p>
              <ul>
                <li>Deliver services exactly as described</li>
                <li>Maintain transparent communication</li>
                <li>Honor all commitments and deadlines</li>
                <li>Provide accurate service descriptions</li>
                <li>Handle disputes professionally</li>
              </ul>

              <h3>6. Buyer Responsibilities</h3>
              <p>
                Buyers must ensure clear communication and honor their
                commitments to maintain a trustworthy marketplace environment.
              </p>
              <ul>
                <li>Provide detailed requirements</li>
                <li>Communicate expectations clearly</li>
                <li>Honor payment commitments</li>
                <li>Respect service provider terms</li>
                <li>Report issues promptly</li>
              </ul>

              <h3>7. Intellectual Property Rights</h3>
              <div className="ip-section">
                <p>
                  All users retain their intellectual property rights while
                  using the platform.
                </p>
                <ul>
                  <li>Users maintain ownership of their content</li>
                  <li>Service providers must respect copyright laws</li>
                  <li>Buyers receive rights as specified in agreements</li>
                  <li>Platform content remains OECSL property</li>
                </ul>
              </div>

              <h3>8. Communication & Privacy</h3>
              <div className="communication-section">
                <p>
                  All platform communication must remain professional and within
                  the platform&apos;s messaging system.
                </p>

                <div className="guidelines">
                  <h4>Guidelines:</h4>
                  <ul>
                    <li>Keep all communication on-platform</li>
                    <li>Maintain professional conduct</li>
                    <li>Respect user privacy</li>
                    <li>Report inappropriate behavior</li>
                  </ul>
                </div>
              </div>

              <h3>9. Dispute Resolution Process</h3>
              <p>
                In case of disputes, users agree to follow the platform&apos;s
                resolution process:
              </p>
              <ol>
                <li>Attempt direct resolution between parties</li>
                <li>Use platform&apos;s mediation tools</li>
                <li>Platform intervention (at OECSL&apos;s discretion)</li>
                <li>Legal action (as last resort)</li>
              </ol>

              <h3>10. Legal Disclaimers</h3>
              <div className="legal-section">
                <p className="disclaimer">
                  The platform is provided &quot;as is&quot; without warranties
                  of any kind. OECSL explicitly disclaims all warranties,
                  express or implied.
                </p>

                <div className="liability">
                  <p>
                    <strong>OECSL is not liable for:</strong>
                  </p>
                  <ul>
                    <li>Direct or indirect damages</li>
                    <li>Lost profits or data</li>
                    <li>Service interruptions</li>
                    <li>User disputes</li>
                    <li>Third-party actions</li>
                  </ul>
                </div>
              </div>

              <h3>11. Final Provisions</h3>
              <div className="final-section">
                <p>
                  OECSL reserves the right to modify these terms at any time.
                  Users will be notified of significant changes.
                </p>
                <p>
                  These terms are governed by applicable laws, without regard to
                  conflicts of law principles.
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                onClick={() => setShowTerms(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

ContactModal.propTypes = {
  service: PropTypes.object,
  request: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ContactModal;
