import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaTimes, FaImage, FaUpload, FaPlus } from 'react-icons/fa';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { CATEGORIES } from '../constants/categories';
import '../styles/ServiceFormModal.css';

function ServiceFormModal({ initialData, onSubmit, onClose, isEditing }) {
  const defaultFormData = {
    title: '',
    description: '',
    category: '',
    price: '',
    pricingType: 'fixed',
    deliveryTime: '7d',
    isActive: true,
    requirements: '',
    features: [],
    imageUrl: '',
    tags: [],
    referenceLinks: [],
  };

  const [formData, setFormData] = useState({
    ...defaultFormData,
    ...(initialData || {}),
    tags: initialData?.tags || [],
    features: initialData?.features || [],
    referenceLinks: initialData?.referenceLinks || [],
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...defaultFormData,
        ...initialData,
        tags: initialData.tags || [],
        features: initialData.features || [],
        referenceLinks: initialData.referenceLinks || [],
      });
    }
  }, [initialData]);

  const [uploading, setUploading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      price:
        formData.pricingType === 'on_request'
          ? ''
          : parseFloat(formData.price) || 0,
    };
    onSubmit(submissionData);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    try {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `service-images/${Date.now()}-${file.name}`,
      );

      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      setFormData({
        ...formData,
        imageUrl: downloadURL,
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Failed to upload image. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  // Convert CATEGORIES object to array for mapping
  const categoryOptions = Object.entries(CATEGORIES).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>

          <h2>{isEditing ? 'Edit Service' : 'Add Service'}</h2>

          <form onSubmit={handleSubmit}>
            {/* Service Status Section */}
            <div className="status-toggle">
              <label>Service Status</label>
              <div className="toggle-wrapper">
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={formData.isActive}
                    onChange={(e) =>
                      setFormData({ ...formData, isActive: e.target.checked })
                    }
                  />
                  <span className="toggle-slider"></span>
                </label>
                <span className="toggle-label">
                  {formData.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
              <span className="toggle-hint">
                {formData.isActive
                  ? 'Your service is visible to potential clients'
                  : 'Your service is hidden from the marketplace'}
              </span>
            </div>

            {/* Basic Info Section */}
            <div className="form-section">
              <div className="form-group">
                <label htmlFor="title">
                  Title <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  placeholder="Enter a descriptive title for your service"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  required
                />
                <span className="field-hint">
                  Make it clear and specific (e.g., "Professional Logo Design")
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="category">
                  Category <span className="required">*</span>
                </label>
                <select
                  id="category"
                  value={formData.category}
                  onChange={(e) =>
                    setFormData({ ...formData, category: e.target.value })
                  }
                  required
                >
                  <option value="">Select a category</option>
                  {categoryOptions.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="description">
                  Description <span className="required">*</span>
                </label>
                <textarea
                  id="description"
                  placeholder="Describe your service, process, and what clients can expect"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  required
                />
              </div>
            </div>

            {/* Pricing Section */}
            <div className="form-section">
              <div className="form-group">
                <label htmlFor="pricingType">Pricing Type</label>
                <select
                  id="pricingType"
                  value={formData.pricingType}
                  onChange={(e) =>
                    setFormData({ ...formData, pricingType: e.target.value })
                  }
                >
                  <option value="fixed">Fixed Price</option>
                  <option value="on_request">Price on Request</option>
                </select>
              </div>

              {formData.pricingType === 'fixed' && (
                <div className="form-group">
                  <label htmlFor="price">
                    Price <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    id="price"
                    placeholder="Enter your price"
                    value={formData.price}
                    onChange={(e) =>
                      setFormData({ ...formData, price: e.target.value })
                    }
                    required
                  />
                </div>
              )}

              <div className="form-group">
                <label htmlFor="deliveryTime">Delivery Time</label>
                <select
                  id="deliveryTime"
                  value={formData.deliveryTime}
                  onChange={(e) =>
                    setFormData({ ...formData, deliveryTime: e.target.value })
                  }
                >
                  <option value="1d">1 Day</option>
                  <option value="2d">2 Days</option>
                  <option value="3d">3 Days</option>
                  <option value="5d">5 Days</option>
                  <option value="7d">1 Week</option>
                  <option value="14d">2 Weeks</option>
                  <option value="30d">1 Month</option>
                </select>
              </div>
            </div>

            {/* Additional Details Section */}
            <div className="form-section">
              <div className="form-group">
                <label htmlFor="tags">Tags</label>
                <input
                  type="text"
                  id="tags"
                  placeholder="e.g., logo, branding, design"
                  value={(formData.tags || []).join(', ')}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      tags: e.target.value.split(',').map((t) => t.trim()),
                    })
                  }
                />
                <span className="field-hint">
                  Add relevant keywords to help clients find your service
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="requirements">Requirements</label>
                <textarea
                  id="requirements"
                  placeholder="List what you need from clients to get started"
                  value={formData.requirements}
                  onChange={(e) =>
                    setFormData({ ...formData, requirements: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn-submit">
                {isEditing ? 'Update Service' : 'Add Service'}
              </button>
              <button type="button" className="btn-cancel" onClick={onClose}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

ServiceFormModal.propTypes = {
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
};

export default ServiceFormModal;
