import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  FaPlay,
  FaUsers,
  FaLaptopCode,
  FaHandshake,
  FaChartLine,
  FaArrowRight,
  FaLightbulb,
  FaGraduationCap,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import '../styles/Home.css';

function Home() {
  const [hasWatched, setHasWatched] = useState(false);
  const { currentUser } = useAuth();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const watched = localStorage.getItem('welcomeVideoWatched');
    if (watched) {
      setHasWatched(true);
    }
  }, []);

  const handleVideoEnd = () => {
    localStorage.setItem('welcomeVideoWatched', 'true');
    setHasWatched(true);
    setIsPlaying(false);
  };

  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        '*',
      );
      setIsPlaying(true);
    }
  };

  return (
    <div className="home">
      <motion.section
        className="hero section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="container">
          <h1 className="section-title">
            <span className="highlight-years">14 Years</span> of Empowering Sri
            Lankan Digital Innovation
          </h1>
          <p className="subtitle">
            Join Sri Lanka&apos;s largest tech community where over 175,000
            innovators, developers, and digital entrepreneurs collaborate to
            shape our nation&apos;s digital future
          </p>
          <div className="hero-stats">
            <div className="hero-stat-item">
              <span className="stat-number">199K+</span>
              <span className="stat-label">Community Members</span>
            </div>
            <div className="hero-stat-item">
              <span className="stat-number">25</span>
              <span className="stat-label">Districts Connected</span>
            </div>
            <div className="hero-stat-item">
              <span className="stat-number">1000+</span>
              <span className="stat-label">Success Stories</span>
            </div>
          </div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{ display: 'inline-block', margin: '0 10px' }}
          >
            <Link to="/register" className="btn btn-primary">
              Join Our Community
            </Link>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{ display: 'inline-block', margin: '0 10px' }}
          >
            <Link to="/about" className="btn btn-secondary">
              Our Journey
            </Link>
          </motion.div>
        </div>
      </motion.section>

      {!currentUser && !hasWatched && (
        <motion.div
          className="welcome-video-container"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className={`welcome-video ${isPlaying ? 'playing' : ''}`}>
            <iframe
              ref={videoRef}
              width="560"
              height="315"
              src="https://www.youtube.com/embed/fyNTSfbPBvg?enablejsapi=1&controls=0&showinfo=0&rel=0&autoplay=0"
              title="Welcome Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {!isPlaying && (
              <motion.button
                className="play-button"
                onClick={handlePlayClick}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaPlay />
              </motion.button>
            )}
          </div>
        </motion.div>
      )}

      <motion.section
        className="community-impact section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <div className="container">
          <h2 className="section-title">Community-Driven Innovation</h2>
          <div className="impact-grid">
            <div className="impact-item">
              <FaUsers className="impact-icon" />
              <h3>Nationwide Network</h3>
              <hr className="impact-separator" />
              <p>
                From Jaffna to Galle, our members collaborate across all 25
                districts, breaking geographical barriers and creating
                opportunities for all.
              </p>
            </div>
            <div className="impact-item">
              <FaLaptopCode className="impact-icon" />
              <h3>Digital Excellence</h3>
              <hr className="impact-separator" />
              <p>
                Our community members are working with global clients,
                contributing to Sri Lanka&apos;s recognition as a digital
                service hub.
              </p>
            </div>
            <div className="impact-item">
              <FaHandshake className="impact-icon" />
              <h3>Collaborative Growth</h3>
              <hr className="impact-separator" />
              <p>
                Senior members actively mentor newcomers, sharing knowledge and
                creating a sustainable cycle of growth and innovation.
              </p>
            </div>
            <div className="impact-item">
              <FaChartLine className="impact-icon" />
              <h3>Economic Impact</h3>
              <hr className="impact-separator" />
              <p>
                Together, we&apos;re driving Sri Lanka&apos;s digital economy
                forward, generating opportunities and fostering local
                entrepreneurship.
              </p>
            </div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="offerings section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <div className="container">
          <h2 className="section-title">What We Offer</h2>
          <div className="offerings-grid">
            <motion.div className="offering-item" whileHover={{ scale: 1.02 }}>
              <FaLaptopCode className="offering-icon" />
              <h3>Freelance Success Program</h3>
              <hr className="offering-separator" />
              <ul>
                <li>Profile optimization for global platforms</li>
                <li>Winning proposal writing workshops</li>
                <li>Client communication masterclasses</li>
                <li>Pricing and negotiation strategies</li>
                <li>Personal brand development</li>
              </ul>
            </motion.div>
            <motion.div className="offering-item" whileHover={{ scale: 1.02 }}>
              <FaGraduationCap className="offering-icon" />
              <h3>Skill Development</h3>
              <hr className="offering-separator" />
              <ul>
                <li>Industry-relevant technical training</li>
                <li>Soft skills enhancement workshops</li>
                <li>Portfolio building guidance</li>
                <li>Certification preparation support</li>
                <li>Peer learning opportunities</li>
              </ul>
            </motion.div>
            <motion.div className="offering-item" whileHover={{ scale: 1.02 }}>
              <FaChartLine className="offering-icon" />
              <h3>Business Growth</h3>
              <hr className="offering-separator" />
              <ul>
                <li>Agency scaling strategies</li>
                <li>International payment solutions</li>
                <li>Legal and tax compliance guidance</li>
                <li>Team building support</li>
                <li>Global market access strategies</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="cta-section section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.8 }}
      >
        <div className="container cta-container">
          <div className="cta-content">
            <h2 className="cta-title">Join the OECSL Community Today</h2>
            <p className="cta-description">
              Be part of Sri Lanka&apos;s digital future. Connect with industry
              leaders, learn from experienced mentors, and grow your career in
              the global digital economy. Together, we&apos;re building Sri
              Lanka&apos;s next generation of digital innovators and
              entrepreneurs.
            </p>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Link to="/register" className="btn btn-primary cta-button">
                Become a Member <FaArrowRight className="btn-icon" />
              </Link>
            </motion.div>
          </div>
          <div className="cta-image">
            <img src="/cta-image.jpg" alt="Join OECSL" className="cta-img" />
          </div>
        </div>
      </motion.section>

      <motion.section className="success-stories section">
        <div className="container">
          <h2 className="section-title">Success Stories</h2>
          <div className="stories-grid">
            <motion.div className="story-card">
              <div className="story-content">
                <q>
                  Started as a part-time freelancer in 2020. Now running a
                  6-figure agency with 15 team members, all thanks to
                  OECSL&apos;s guidance.
                </q>
                <p className="story-author">
                  - Sampath K., Digital Marketing Agency Owner
                </p>
              </div>
            </motion.div>
            <motion.div className="story-card">
              <div className="story-content">
                <q>
                  OECSL&apos;s mentorship helped me transition from a local job
                  to international clients. Now earning 5x more as a freelance
                  developer.
                </q>
                <p className="story-author">- Priya M., Full Stack Developer</p>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>
    </div>
  );
}

export default Home;
