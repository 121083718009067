import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/global.css';
import './styles/header.css';
import './styles/profile.css';
import './styles/publicProfile.css';
import './styles/services.css';

const container = document.getElementById('root');
const root = createRoot(container);

const originalError = console.error;
console.error = (...args) => {
  if (/Please update the following components/.test(args[0])) {
    return;
  }
  originalError.call(console, ...args);
};

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
