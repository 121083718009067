import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  FaBars,
  FaTimes,
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaComments,
  FaSignInAlt,
} from 'react-icons/fa';
import FeedbackForm from './FeedbackForm';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const menuRef = useRef(null);
  const profileMenuRef = useRef(null);
  const [showFeedback, setShowFeedback] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setIsProfileMenuOpen(false);
      }
    };

    document.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [scrolled]);

  const closeMenus = () => {
    setIsMenuOpen(false);
    setIsProfileMenuOpen(false);
  };

  return (
    <header className={`header ${scrolled ? 'scrolled' : ''}`}>
      <div className="container header-container">
        <Link to="/" className="logo">
          <img src="/logo.png" alt="OECSL Logo" className="logo-image" />
        </Link>

        <nav className={`nav ${isMenuOpen ? 'active' : ''}`} ref={menuRef}>
          <Link
            to="/"
            className={location.pathname === '/' ? 'active' : ''}
            onClick={closeMenus}
          >
            Home
          </Link>
          <Link
            to="/skills"
            className={location.pathname === '/skills' ? 'active' : ''}
            onClick={closeMenus}
          >
            Explore Skills
          </Link>
          <Link
            to="/about"
            className={location.pathname === '/about' ? 'active' : ''}
            onClick={closeMenus}
          >
            About
          </Link>
          <Link
            to="/events"
            className={location.pathname === '/events' ? 'active' : ''}
            onClick={closeMenus}
          >
            Events
          </Link>
          <Link
            to="/blog"
            className={location.pathname === '/blog' ? 'active' : ''}
            onClick={closeMenus}
          >
            Blog
          </Link>
          <Link
            to="/contact"
            className={location.pathname === '/contact' ? 'active' : ''}
            onClick={closeMenus}
          >
            Contact
          </Link>
          <Link
            to="/store"
            className={location.pathname === '/store' ? 'active' : ''}
            onClick={closeMenus}
          >
            Store
          </Link>
          <Link
            to="/marketplace"
            className={location.pathname === '/marketplace' ? 'active' : ''}
            onClick={closeMenus}
          >
            Marketplace
          </Link>
        </nav>

        <div className="header-actions">
          <button
            className="feedback-button"
            onClick={() => setShowFeedback(true)}
            title="Give Feedback"
          >
            <FaComments />
          </button>

          {currentUser ? (
            <div className="profile-menu" ref={profileMenuRef}>
              <button onClick={toggleProfileMenu} className="profile-icon">
                <img
                  src={currentUser.photoURL || '/default-avatar.png'}
                  alt="Profile"
                  className="profile-image"
                />
              </button>
              {isProfileMenuOpen && (
                <div className="dropdown-menu">
                  <div className="dropdown-header">
                    <img
                      src={currentUser.photoURL || '/default-avatar.png'}
                      alt="Profile"
                      className="dropdown-profile-image"
                    />
                    <div className="dropdown-user-info">
                      <span className="dropdown-username">
                        {currentUser.displayName || currentUser.email}
                      </span>
                      <span className="dropdown-email">
                        {currentUser.email}
                      </span>
                    </div>
                  </div>
                  <Link
                    to="/user-dashboard"
                    className="dropdown-item"
                    onClick={closeMenus}
                  >
                    <FaUser /> Dashboard
                  </Link>
                  {currentUser.isAdmin && (
                    <Link
                      to="/admin"
                      className="dropdown-item"
                      onClick={closeMenus}
                    >
                      <FaCog /> Admin Panel
                    </Link>
                  )}
                  <button
                    onClick={() => {
                      handleLogout();
                      closeMenus();
                    }}
                    className="dropdown-item logout-button"
                  >
                    <FaSignOutAlt /> Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to="/login" className="login-button" onClick={closeMenus}>
              <FaSignInAlt />
            </Link>
          )}

          <button className="mobile-menu-button" onClick={toggleMenu}>
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

        {showFeedback && (
          <>
            <div
              className="modal-overlay"
              onClick={() => setShowFeedback(false)}
            />
            <FeedbackForm onClose={() => setShowFeedback(false)} />
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
