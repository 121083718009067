import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  FaEdit,
  FaTrash,
  FaEnvelope,
  FaPlus,
  FaFilter,
  FaSearch,
  FaStore,
  FaList,
  FaClipboardList,
  FaHeart,
  FaRegHeart,
  FaBookmark,
  FaRegBookmark,
  FaShareAlt,
  FaStar,
  FaToggleOn,
  FaToggleOff,
} from 'react-icons/fa';
import { db } from '../firebase';
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  query,
  where,
  orderBy,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import '../styles/Marketplace.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ServiceFormModal,
  ContactModal,
  MyServices,
  ServiceViewModal,
  ServiceCard,
} from './';
import MessagesDrawer from './MessagesDrawer';
import RequestFormModal from './RequestFormModal';
import ServiceRequests from './ServiceRequests';
import { Helmet } from 'react-helmet-async';
import defaultAvatar from '../assets/default-avatar.png'; // Make sure to add this image to assets
import { CATEGORIES } from '../constants/categories';

function Marketplace({ initialView = 'marketplace' }) {
  const { currentUser } = useAuth();
  const [services, setServices] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterCategory, setFilterCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [showMessages, setShowMessages] = useState(false);
  const [activeView, setActiveView] = useState(initialView);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [lovedServices, setLovedServices] = useState({});
  const [savedServices, setSavedServices] = useState({});
  const [showServiceForm, setShowServiceForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // Number of listings per page
  const servicesGridRef = useRef(null); // Add this ref

  // Initial empty service form state
  const emptyServiceForm = {
    title: '',
    description: '',
    price: '',
    pricingType: 'fixed',
    category: '',
    imageUrl: '',
    isActive: true,
    deliveryTime: '7d',
    tags: [],
    features: [''],
    requirements: '',
  };

  const [serviceForm, setServiceForm] = useState(emptyServiceForm);

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentListings = services.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(services.length / itemsPerPage);

  // Update handlePageChange to use ref
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll smoothly to top of services grid
    window.scrollTo({
      top: servicesGridRef.current?.offsetTop - 100 || 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    fetchServices();
    fetchUserInteractions();
    // Check if we should show the new listing form
    const params = new URLSearchParams(location.search);
    if (params.get('action') === 'new-listing') {
      setShowServiceForm(true);
    }
  }, [filterCategory, currentUser, location]);

  const fetchUserInteractions = async () => {
    if (!currentUser) return;

    try {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const userData = userDoc.data();

      setLovedServices(userData?.lovedServices || {});
      setSavedServices(userData?.savedServices || {});
    } catch (error) {
      console.error('Error fetching user interactions:', error);
    }
  };

  const fetchServices = async () => {
    setLoading(true);
    try {
      // First get all services
      const servicesRef = collection(db, 'services');
      const snapshot = await getDocs(servicesRef);

      // Map the raw data first
      const rawServices = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Filter active services
      const activeServices = rawServices.filter(
        (service) => service.isActive !== false,
      );

      // Filter by category
      const categoryFiltered = activeServices.filter(
        (service) =>
          filterCategory === 'all' || service.category === filterCategory,
      );

      // Get all users to count loves
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersData = usersSnapshot.docs.map((doc) => doc.data());

      // Process each service with user data and accurate love counts
      const servicesData = await Promise.all(
        categoryFiltered.map(async (serviceData) => {
          // Get user data
          const userDoc = await getDoc(doc(db, 'users', serviceData.userId));
          const userData = userDoc.data() || {};

          // Count total loves for this service
          const loveCount = usersData.reduce((count, user) => {
            return (
              count + (user.lovedServices?.[serviceData.id] === true ? 1 : 0)
            );
          }, 0);

          return {
            ...serviceData,
            fullName: userData.fullName || 'Anonymous',
            profilePictureUrl: userData.profilePictureUrl,
            loveCount,
          };
        }),
      );

      setServices(servicesData);
    } catch (error) {
      console.error('Error fetching services:', error);
      setError('Failed to fetch services');
    } finally {
      setLoading(false);
    }
  };

  const handleServiceSubmit = async (formData) => {
    try {
      if (formData.id) {
        await updateDoc(doc(db, 'services', formData.id), {
          ...formData,
          updatedAt: serverTimestamp(),
        });
      } else {
        await addDoc(collection(db, 'services'), {
          ...formData,
          userId: currentUser.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      }
      setShowAddModal(false);
      fetchServices();
    } catch (error) {
      console.error('Error submitting service:', error);
      // Handle error (show notification, etc.)
    }
  };

  // Update view handler
  const handleViewChange = (view) => {
    setActiveView(view);
    if (view === 'requests') {
      navigate('/marketplace/requests');
    } else {
      navigate('/marketplace');
    }
  };

  const handleLoveService = async (serviceId) => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const newLoveStatus = !lovedServices[serviceId];

      // Update Firestore
      await updateDoc(userRef, {
        [`lovedServices.${serviceId}`]: newLoveStatus,
      });

      // Update local lovedServices state
      setLovedServices((prev) => ({
        ...prev,
        [serviceId]: newLoveStatus,
      }));

      // Get current love count from all users
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const loveCount = usersSnapshot.docs.reduce((count, doc) => {
        const userData = doc.data();
        return count + (userData.lovedServices?.[serviceId] === true ? 1 : 0);
      }, 0);

      // Update local services state with accurate count
      setServices((prevServices) =>
        prevServices.map((service) =>
          service.id === serviceId
            ? {
                ...service,
                loveCount,
              }
            : service,
        ),
      );
    } catch (error) {
      console.error('Error updating love status:', error);
    }
  };

  const handleSaveService = async (serviceId) => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const newSaveStatus = !savedServices[serviceId];

      await updateDoc(userRef, {
        [`savedServices.${serviceId}`]: newSaveStatus,
      });

      setSavedServices((prev) => ({
        ...prev,
        [serviceId]: newSaveStatus,
      }));
    } catch (error) {
      console.error('Error updating save status:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>OECSL Marketplace</title>
        <meta
          name="description"
          content="Browse and list services on OECSL Marketplace"
        />
      </Helmet>
      <div className="marketplace-page">
        {/* Navigation and Filter Bar */}
        <div className="marketplace-nav">
          <button
            className={`btn ${activeView === 'marketplace' ? 'active' : ''}`}
            onClick={() => handleViewChange('marketplace')}
          >
            <FaStore /> Marketplace
          </button>

          <button
            className={`btn ${activeView === 'requests' ? 'active' : ''}`}
            onClick={() => handleViewChange('requests')}
          >
            <FaClipboardList /> Requests
          </button>

          {currentUser && (
            <>
              <button
                className={`btn ${activeView === 'myServices' ? 'active' : ''}`}
                onClick={() => handleViewChange('myServices')}
              >
                <FaList /> My Services
              </button>
              <button
                className="btn"
                onClick={() => {
                  setServiceForm(emptyServiceForm);
                  setShowAddModal(true);
                }}
              >
                <FaPlus /> List New Service
              </button>
              <button
                className="btn"
                onClick={() => {
                  if (!currentUser) {
                    navigate('/login');
                    return;
                  }
                  setShowRequestForm(true);
                }}
              >
                <FaPlus /> Submit Request
              </button>
              <button className="btn" onClick={() => setShowMessages(true)}>
                <FaEnvelope /> Messages
              </button>
            </>
          )}

          <select
            className="filter-select"
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
          >
            <option value="all">All Categories</option>
            {Object.entries(CATEGORIES).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>

          <input
            type="text"
            className="search-input"
            placeholder="Search services..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Main Content */}
        <div className="marketplace-content">
          {loading ? (
            <div className="loading">Loading...</div>
          ) : error ? (
            <div className="error">{error}</div>
          ) : activeView === 'myServices' && currentUser ? (
            <MyServices />
          ) : activeView === 'requests' ? (
            <ServiceRequests />
          ) : (
            <div className="services-grid" ref={servicesGridRef}>
              {currentListings
                // First create a filtered array
                .reduce((unique, service) => {
                  // Only add if not already in the array
                  if (!unique.some((s) => s.id === service.id)) {
                    unique.push(service);
                  }
                  return unique;
                }, [])
                // Then filter by search term
                .filter(
                  (service) =>
                    service.title
                      ?.toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    service.description
                      ?.toLowerCase()
                      .includes(searchTerm.toLowerCase()),
                )
                // Finally map to components
                .map((service) => (
                  <ServiceCard
                    key={service.id} // Just use the service ID as key
                    service={service}
                    isOwner={currentUser?.uid === service.userId}
                    onContactClick={() => {
                      setSelectedService(service);
                      setShowContactModal(true);
                    }}
                    isLoved={lovedServices[service.id] || false}
                    onLove={handleLoveService}
                    isSaved={savedServices[service.id] || false}
                    onSave={handleSaveService}
                    onEdit={() => {
                      setServiceForm(service);
                      setShowAddModal(true);
                    }}
                    onDelete={async () => {
                      if (
                        window.confirm(
                          'Are you sure you want to delete this service?',
                        )
                      ) {
                        await deleteDoc(doc(db, 'services', service.id));
                        fetchServices();
                      }
                    }}
                    onToggleActive={async () => {
                      await updateDoc(doc(db, 'services', service.id), {
                        isActive: !service.isActive,
                      });
                      fetchServices();
                    }}
                  />
                ))}
            </div>
          )}

          {/* Show pagination if there are enough items, regardless of user status */}
          {services.length > itemsPerPage && (
            <div className="pagination">
              <button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
                className="page-btn first"
              >
                First
              </button>

              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="page-btn prev"
              >
                Previous
              </button>

              <div className="page-numbers">
                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .filter((num) => {
                    return (
                      num === 1 ||
                      num === totalPages ||
                      Math.abs(currentPage - num) <= 2
                    );
                  })
                  .map((number, index, array) => (
                    <React.Fragment key={number}>
                      {index > 0 && array[index - 1] !== number - 1 && (
                        <span className="page-ellipsis">...</span>
                      )}
                      <button
                        className={`page-btn number ${
                          currentPage === number ? 'active' : ''
                        }`}
                        onClick={() => handlePageChange(number)}
                      >
                        {number}
                      </button>
                    </React.Fragment>
                  ))}
              </div>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="page-btn next"
              >
                Next
              </button>

              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
                className="page-btn last"
              >
                Last
              </button>
            </div>
          )}

          {/* Show pagination info for all users */}
          {services.length > 0 && (
            <div className="pagination-info">
              <p>
                Showing {indexOfFirstItem + 1} to{' '}
                {Math.min(indexOfLastItem, services.length)} of{' '}
                {services.length} listings
              </p>
            </div>
          )}
        </div>

        {/* Modals */}
        {showAddModal && (
          <ServiceFormModal
            service={serviceForm}
            onSubmit={handleServiceSubmit}
            onClose={() => setShowAddModal(false)}
          />
        )}

        {showContactModal && selectedService && (
          <ContactModal
            service={selectedService}
            onSubmit={async (message) => {
              setShowContactModal(false);
              // Optionally show success message
              alert('Message sent successfully!');
            }}
            onClose={() => setShowContactModal(false)}
          />
        )}

        {showRequestForm && (
          <RequestFormModal
            onSubmit={async (formData) => {
              try {
                await addDoc(collection(db, 'buyerRequests'), {
                  ...formData,
                  userId: currentUser.uid,
                  createdAt: serverTimestamp(),
                  status: 'open',
                });
                setShowRequestForm(false);
                handleViewChange('requests'); // Navigate to requests view after submission
              } catch (error) {
                console.error('Error submitting request:', error);
                alert('Failed to submit request. Please try again.');
              }
            }}
            onClose={() => setShowRequestForm(false)}
          />
        )}

        {/* Messages Drawer */}
        <MessagesDrawer
          isOpen={showMessages}
          onClose={() => setShowMessages(false)}
        />
      </div>
    </>
  );
}

Marketplace.propTypes = {
  initialView: PropTypes.oneOf(['marketplace', 'requests', 'myServices']),
};

export default Marketplace;
