import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import {
  validateInviteCode,
  createInvitationRequest,
} from '../services/firestoreService';
import '../styles/Register.css';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  setDoc,
  increment,
  arrayUnion,
  serverTimestamp,
  getDoc,
} from 'firebase/firestore';
import { db, auth } from '../firebase';

function Register() {
  const [searchParams] = useSearchParams();
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [requestData, setRequestData] = useState({
    email: '',
    name: '',
    loading: false,
    error: '',
    success: '',
  });

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    invitationCode: '',
    error: '',
    loading: false,
    isCodeValid: false,
    validatedCode: null,
  });

  const { signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Get code from URL query params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (code) {
      setFormData((prev) => ({
        ...prev,
        invitationCode: code,
      }));

      // Validate the code immediately
      validateInviteCode(code)
        .then((result) => {
          setFormData((prev) => ({
            ...prev,
            isCodeValid: result.valid,
            validatedCode: result.valid ? result.docId : null,
            error: result.valid ? '' : result.message,
          }));
        })
        .catch((error) => {
          setFormData((prev) => ({
            ...prev,
            isCodeValid: false,
            validatedCode: null,
            error: error.message,
          }));
        });
    }
  }, [location]);

  // Validate invitation code from URL
  useEffect(() => {
    const inviteCode = searchParams.get('invite');
    if (inviteCode) {
      setFormData((prev) => ({ ...prev, loading: true }));
      validateInviteCode(inviteCode)
        .then((result) => {
          console.log('Validation result:', result);
          if (result.valid) {
            console.log('Code is valid:', result.codeData);
            setFormData((prev) => ({
              ...prev,
              invitationCode: inviteCode,
              isCodeValid: true,
              validatedCode: result.docId,
              error: '',
              loading: false,
            }));
          } else {
            console.log('Code is invalid:', result.message);
            setFormData((prev) => ({
              ...prev,
              invitationCode: inviteCode,
              isCodeValid: false,
              validatedCode: null,
              error: result.message,
              loading: false,
            }));
          }
        })
        .catch((error) => {
          console.error('Validation error:', error);
          setFormData((prev) => ({
            ...prev,
            invitationCode: inviteCode,
            isCodeValid: false,
            validatedCode: null,
            error: 'Error validating invitation code',
            loading: false,
          }));
        });
    }
  }, [searchParams]);

  // Validate invitation code when manually entered
  useEffect(() => {
    if (!searchParams.get('invite') && formData.invitationCode) {
      validateInviteCode(formData.invitationCode)
        .then((result) => {
          setFormData((prev) => ({
            ...prev,
            isCodeValid: result.valid,
            validatedCode: result.valid ? result.docId : null,
            error: result.valid ? '' : result.message,
          }));
        })
        .catch((error) => {
          setFormData((prev) => ({
            ...prev,
            isCodeValid: false,
            validatedCode: null,
            error: error.message,
          }));
        });
    }
  }, [formData.invitationCode, searchParams]);

  useEffect(() => {
    // Check for message from login redirect
    if (location.state?.message) {
      setFormData((prev) => ({
        ...prev,
        error: location.state.message,
      }));
      // Clear the location state
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const handleGoogleSignIn = async () => {
    try {
      setFormData((prev) => ({ ...prev, loading: true, error: '' }));

      const result = await signInWithGoogle();
      console.log('Google sign-in result:', result);

      if (!result || !result.user) {
        throw new Error('Failed to get user data from Google sign-in');
      }

      // Create new user document
      await setDoc(doc(db, 'users', result.user.uid), {
        email: result.user.email,
        displayName: result.user.displayName,
        photoURL: result.user.photoURL,
        createdAt: serverTimestamp(),
        invitationCode: formData.invitationCode.toUpperCase() || null,
        skills: {},
        socialLinks: {},
        countriesServed: [],
        profileComplete: false,
        emailVerified: result.user.emailVerified,
      });

      // Navigate to dashboard
      navigate('/user-dashboard');
    } catch (error) {
      console.error('Google sign-in error:', error);
      setFormData((prev) => ({
        ...prev,
        error: error.message || 'Failed to sign in with Google',
        loading: false,
      }));
    }
  };

  const handleRequestInvitation = async (e) => {
    e.preventDefault();
    try {
      setRequestData((prev) => ({ ...prev, loading: true, error: '' }));

      await createInvitationRequest({
        email: requestData.email,
        name: requestData.name,
        requestDate: new Date().toISOString(),
      });

      setRequestData((prev) => ({
        ...prev,
        success:
          'Invitation request sent successfully! We will contact you soon.',
        loading: false,
      }));

      // Hide form after successful submission
      setTimeout(() => {
        setShowRequestForm(false);
        setRequestData({
          email: '',
          name: '',
          loading: false,
          error: '',
          success: '',
        });
      }, 3000);
    } catch (error) {
      setRequestData((prev) => ({
        ...prev,
        error: error.message || 'Failed to submit request',
        loading: false,
      }));
    }
  };

  return (
    <div className="register-container">
      <div className="register-card">
        <h2 className="register-title">Sign Up</h2>
        {formData.error && (
          <div className="error-message">{formData.error}</div>
        )}

        <div className="signup-steps">
          <div className="invitation-code-section">
            <div className="form-group">
              <label htmlFor="invitation-code">
                Invitation Code (Optional)
              </label>
              <div className="invitation-code-wrapper">
                <input
                  type="text"
                  id="invitation-code"
                  value={formData.invitationCode}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      invitationCode: e.target.value,
                      error: '',
                    }))
                  }
                  className="form-input"
                  placeholder="Enter invitation code if you have one"
                />
              </div>
            </div>
          </div>

          <div className="google-signin-section">
            <button
              onClick={handleGoogleSignIn}
              className="btn btn-google btn-block"
              disabled={formData.loading}
            >
              <FaGoogle className="google-icon" />
              {formData.loading ? 'Signing up...' : 'Sign up with Google'}
            </button>
          </div>
        </div>

        <div className="register-footer">
          <p>
            Already have an account?{' '}
            <Link to="/login" className="login-link">
              Log In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
