import React, { useState, useEffect } from 'react';
import {
  getCatalogs,
  updateCatalog,
  updateCatalogs,
} from '../services/firestoreService';
import '../styles/catalogManagement.css';

function CatalogManagement() {
  const [catalogs, setCatalogs] = useState({
    skills: {
      'Graphic Design': [
        'Logo Design',
        'Cartoon & Illustration',
        'Flyers / Brochures',
        'Social Media Graphics',
        'Branding & Identity Design',
        'Packaging Design',
        'Print Design',
      ],
      'Content Writing & Copywriting': [
        'Blog Writing',
        'SEO Writing',
        'Copywriting for Ads / Sales',
        'Technical Writing',
        'UX Writing',
        'Ghostwriting',
        'Creative Writing (Poetry, Fiction)',
        'Editing & Proofreading',
      ],
      'Development & Programming': [
        'Frontend Development',
        'Backend Development',
        'Full-Stack Development',
        'Mobile App Development',
        'Game Development',
        'Software Development',
        'AI / Machine Learning',
        'DevOps / Cloud Engineering',
      ],
      'Marketing & Advertising': [
        'SEO Strategy',
        'Social Media Marketing',
        'Pay-Per-Click (PPC) Campaigns',
        'Email Marketing',
        'Influencer Marketing',
        'Content Marketing',
        'Affiliate Marketing',
      ],
      'Video & Multimedia Production': [
        'Video Editing',
        'Motion Graphics',
        'Animation (2D / 3D)',
        'Audio Production / Podcast Editing',
        'Voiceover Acting',
        'Photography',
        'Videography / Cinematography',
      ],
      'Business & Consulting Services': [
        'Project Management',
        'Business Strategy Consulting',
        'Virtual Assistance',
        'Accounting & Finance',
        'Recruitment / HR Consulting',
        'Customer Support Services',
      ],
    },
    technologies: {
      'Graphic Design Tools': [
        'Photoshop',
        'Illustrator',
        'Canva',
        'CorelDRAW',
        'Figma',
        'InDesign',
        'Sketch',
      ],
      'Development Tools & Technologies': [
        'HTML',
        'CSS',
        'JavaScript',
        'React.js',
        'Vue.js',
        'Node.js',
        'Django',
        'Ruby on Rails',
        'PHP',
        'Laravel',
        'Swift (iOS)',
        'Kotlin (Android)',
        'Flutter',
        'React Native',
        'Unity',
        'Unreal Engine',
        'Godot',
        'TensorFlow',
        'PyTorch',
        'OpenAI',
        'scikit-learn',
      ],
      'Writing & Content Tools': [
        'Google Docs',
        'Microsoft Word',
        'Grammarly',
        'Scrivener',
        'SurferSEO',
        'Hemingway Editor',
      ],
      'Marketing Tools': [
        'Google Analytics',
        'Google Ads',
        'Facebook Ads Manager',
        'HubSpot',
        'MailChimp',
        'SEMrush',
        'Hootsuite',
      ],
      'Video Editing & Multimedia Tools': [
        'Adobe Premiere Pro',
        'Final Cut Pro',
        'DaVinci Resolve',
        'After Effects',
        'Audacity',
        'Ableton Live',
        'GarageBand',
      ],
      'Frontend Development': [
        'HTML5',
        'CSS3',
        'JavaScript (ES6+)',
        'TypeScript',
        'React.js',
        'Vue.js',
        'Angular',
        'Next.js',
        'Nuxt.js',
        'Svelte',
        'Redux',
        'MobX',
        'Zustand',
        'Sass/SCSS',
        'Less',
        'Tailwind CSS',
        'Bootstrap',
        'Material UI',
        'Chakra UI',
        'Styled Components',
        'Webpack',
        'Vite',
        'Rollup',
        'Jest',
        'React Testing Library',
        'Cypress',
        'WebGL',
        'Three.js',
        'D3.js',
        'Progressive Web Apps (PWA)',
      ],
      'Backend Development': [
        'Node.js',
        'Express.js',
        'NestJS',
        'Python',
        'Django',
        'Flask',
        'FastAPI',
        'Java',
        'Spring Boot',
        'PHP',
        'Laravel',
        'CodeIgniter',
        'Ruby',
        'Ruby on Rails',
        'Go',
        'Gin',
        'C#',
        'ASP.NET Core',
        'Rust',
        'GraphQL',
        'REST API',
        'WebSocket',
        'gRPC',
        'MongoDB',
        'PostgreSQL',
        'MySQL',
        'Redis',
        'Elasticsearch',
        'Apache Kafka',
        'RabbitMQ',
        'Microservices Architecture',
        'OAuth/JWT Authentication',
        'API Security',
      ],
      'Mobile Development': [
        'iOS (Swift)',
        'iOS (SwiftUI)',
        'Android (Kotlin)',
        'Android (Java)',
        'React Native',
        'Flutter',
        'Xamarin',
        'Ionic',
        'Progressive Web Apps (PWA)',
        'Native iOS Development',
        'Native Android Development',
        'Mobile UI/UX Design',
        'App Performance Optimization',
        'Mobile App Security',
        'Push Notifications',
        'Mobile Analytics',
        'App Store Optimization',
        'Mobile Backend (BaaS)',
        'SQLite',
        'Core Data',
        'Room Database',
        'Mobile Testing',
        'CI/CD for Mobile',
      ],
      'Game Development': [
        'Unity',
        'Unity C#',
        'Unreal Engine',
        'Unreal C++',
        'Godot',
        'GDScript',
        'C++',
        'OpenGL',
        'WebGL',
        'Three.js',
        'Phaser.js',
        'Babylon.js',
        'PlayCanvas',
        'Cocos2d',
        'SDL2',
        'SFML',
        '2D Game Development',
        '3D Game Development',
        'Game Physics',
        'Game AI',
        'Multiplayer Networking',
        'Game UI/UX',
        'Game Audio',
        'Shader Programming',
        'Game Optimization',
        'Mobile Game Development',
        'VR/AR Development',
        'Game Testing',
      ],
      'AI & Machine Learning': [
        'TensorFlow',
        'PyTorch',
        'Keras',
        'Scikit-learn',
        'OpenCV',
        'NLTK',
        'Spacy',
        'Pandas',
        'NumPy',
        'Computer Vision',
        'Natural Language Processing',
        'Deep Learning',
        'Machine Learning',
        'Neural Networks',
        'Reinforcement Learning',
        'Data Science',
        'Data Visualization',
        'OpenAI API Integration',
        'GPT Models',
        'BERT',
        'Transformers',
        'AI Model Training',
        'AI Model Deployment',
        'MLOps',
        'AI Ethics',
        'Chatbot Development',
        'Image Recognition',
        'Speech Recognition',
        'Recommendation Systems',
        'Time Series Analysis',
      ],
      'Cloud & DevOps Technologies': [
        'AWS',
        'Amazon EC2',
        'Amazon S3',
        'AWS Lambda',
        'Amazon RDS',
        'Google Cloud Platform',
        'Google Kubernetes Engine',
        'Cloud Functions',
        'Microsoft Azure',
        'Azure Functions',
        'Azure DevOps',
        'Docker',
        'Kubernetes',
        'CI/CD',
        'Jenkins',
        'GitHub Actions',
        'GitLab CI',
        'CircleCI',
        'Terraform',
        'Ansible',
        'Chef',
        'Puppet',
        'Linux Administration',
        'Shell Scripting',
        'Bash',
        'PowerShell',
        'Monitoring (Prometheus)',
        'Grafana',
        'ELK Stack',
        'Cloud Security',
        'Infrastructure as Code',
        'Service Mesh (Istio)',
        'Serverless Architecture',
        'Microservices Deployment',
        'Container Orchestration',
      ],
    },
    platforms: {
      'Web & eCommerce Platforms': [
        'Shopify',
        'WooCommerce',
        'Magento',
        'BigCommerce',
        'Wix',
        'Squarespace',
        'WordPress',
      ],
      'Social Media Platforms': [
        'Instagram',
        'Facebook',
        'LinkedIn',
        'Twitter / X',
        'TikTok',
        'YouTube',
        'Pinterest',
      ],
      'Email Marketing Platforms': [
        'MailChimp',
        'HubSpot',
        'Klaviyo',
        'ConvertKit',
        'ActiveCampaign',
      ],
      'Project Management & Collaboration Tools': [
        'Trello',
        'Asana',
        'Jira',
        'Monday.com',
        'Slack',
        'Notion',
      ],
      'Cloud Hosting & Storage Platforms': [
        'AWS (Amazon Web Services)',
        'Google Cloud Platform',
        'Microsoft Azure',
        'Dropbox',
        'Google Drive',
        'OneDrive',
      ],
      'Payment & Invoicing Platforms': [
        'PayPal',
        'Stripe',
        'Square',
        'Wise',
        'QuickBooks',
        'Xero',
      ],
    },
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('skills');
  const [editingCategory, setEditingCategory] = useState(null);
  const [newItemText, setNewItemText] = useState('');

  useEffect(() => {
    fetchCatalogs();
  }, []);

  const fetchCatalogs = async () => {
    setLoading(true);
    setError(null);
    try {
      const fetchedCatalogs = await getCatalogs();
      if (fetchedCatalogs) {
        setCatalogs(fetchedCatalogs);
      }
    } catch (err) {
      console.error('Error fetching catalogs:', err);
      setError('Failed to fetch catalogs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCatalog = async (category) => {
    setLoading(true);
    setError(null);
    try {
      // Get the array of items for this category
      const categoryItems = catalogs[activeTab][category];

      // Ensure we're sending just the array of items
      await updateCatalog(activeTab, category, categoryItems);
      setEditingCategory(null);
    } catch (err) {
      console.error('Error updating catalog:', err);
      setError('Failed to update catalog. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddItems = (category) => {
    if (newItemText.trim()) {
      const items = newItemText
        .split(/[\n,]+/)
        .map((item) => item.trim())
        .filter((item) => item !== '');

      setCatalogs((prevCatalogs) => ({
        ...prevCatalogs,
        [activeTab]: {
          ...prevCatalogs[activeTab],
          [category]: [...(prevCatalogs[activeTab][category] || []), ...items],
        },
      }));
      setNewItemText('');
    }
  };

  const handleRemoveItem = (category, index) => {
    setCatalogs((prevCatalogs) => ({
      ...prevCatalogs,
      [activeTab]: {
        ...prevCatalogs[activeTab],
        [category]: prevCatalogs[activeTab][category].filter(
          (_, i) => i !== index,
        ),
      },
    }));
  };

  const handleEditItem = (category, index, newValue) => {
    setCatalogs((prevCatalogs) => ({
      ...prevCatalogs,
      [activeTab]: {
        ...prevCatalogs[activeTab],
        [category]: prevCatalogs[activeTab][category].map((item, i) =>
          i === index ? newValue : item,
        ),
      },
    }));
  };

  if (loading) return <div>Loading catalogs...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="catalog-management">
      <h2 className="section-title">Catalog Management</h2>
      <div className="catalog-tabs">
        <button onClick={() => setActiveTab('skills')}>Skills</button>
        <button onClick={() => setActiveTab('technologies')}>
          Technologies
        </button>
        <button onClick={() => setActiveTab('platforms')}>Platforms</button>
      </div>
      <div className="catalog-content">
        {Object.entries(catalogs[activeTab] || {}).map(([category, items]) => (
          <div key={category} className="catalog-category">
            <h3>{category}</h3>
            {editingCategory === category ? (
              <div>
                <ul>
                  {(items || []).map((item, index) => (
                    <li key={index}>
                      <input
                        value={item}
                        onChange={(e) =>
                          handleEditItem(category, index, e.target.value)
                        }
                      />
                      <button onClick={() => handleRemoveItem(category, index)}>
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="add-item">
                  <textarea
                    value={newItemText}
                    onChange={(e) => setNewItemText(e.target.value)}
                    placeholder="Enter new items (separate by commas or new lines)"
                    rows={3}
                  />
                  <button onClick={() => handleAddItems(category)}>
                    Add Items
                  </button>
                </div>
                <div className="action-buttons">
                  <button onClick={() => handleUpdateCatalog(category)}>
                    Save Changes
                  </button>
                  <button onClick={() => setEditingCategory(null)}>
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <ul>
                  {(items || []).map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <button onClick={() => setEditingCategory(category)}>
                  Edit
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <button className="btn btn-primary" onClick={updateCatalogs}>
        Update Technology Catalogs
      </button>
    </div>
  );
}

export default CatalogManagement;
