import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import UserManagement from './UserManagement';
import EventManagement from './EventManagement';
import PostManagement from './PostManagement';
import PageContentManagement from './PageContentManagement';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import CatalogManagement from './CatalogManagement';
import BadgeManagement from './BadgeManagement';
import FeedbackManagement from './FeedbackManagement';
import InvitationRequestManagement from './InvitationRequestManagement';
import OrderManagement from './OrderManagement';
import ListingManagement from './ListingManagement';
import '../styles/Admin.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

function Admin() {
  const [selectedOption, setSelectedOption] = useState(null);
  const { currentUser } = useAuth();
  const [stats, setStats] = useState({
    totalUsers: 0,
    publicProfiles: 0,
    totalServices: 0,
    totalEvents: 0,
    totalPosts: 0,
    activeInvitations: 0,
    pendingRequests: 0,
    totalOrders: 0,
    messages: {
      total: 0,
      unread: 0,
      thisMonth: 0,
    },
    store: {
      totalSales: 0,
      activeListings: 0,
      completedOrders: 0,
      pendingOrders: 0,
    },
    membershipGrowth: {
      thisMonth: 0,
      lastMonth: 0,
    },
    engagement: {
      activeUsers: 0,
      completedProfiles: 0,
    },
  });

  const calculateProfileCompletion = (userData) => {
    if (!userData) return 0;

    const requiredFields = [
      'fullName',
      'email',
      'phone',
      'location',
      'bio',
      'profilePictureUrl',
    ];

    const completedFields = requiredFields.filter(
      (field) => userData[field] && userData[field].toString().trim() !== '',
    );

    return (completedFields.length / requiredFields.length) * 100;
  };

  const fetchStats = async () => {
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const eventsSnapshot = await getDocs(collection(db, 'events'));
      const postsSnapshot = await getDocs(collection(db, 'posts'));
      const servicesSnapshot = await getDocs(collection(db, 'services'));
      const ordersSnapshot = await getDocs(collection(db, 'orders'));
      const messagesSnapshot = await getDocs(collection(db, 'messages'));
      const storeListingsSnapshot = await getDocs(collection(db, 'listings'));

      const invitationsRef = collection(db, 'invitationCodes');
      const activeInvitationsQuery = query(
        invitationsRef,
        where('isActive', '==', true),
      );
      const invitationsSnapshot = await getDocs(activeInvitationsQuery);

      const requestsRef = collection(db, 'invitationRequests');
      const pendingRequestsQuery = query(
        requestsRef,
        where('status', '==', 'pending'),
      );
      const requestsSnapshot = await getDocs(pendingRequestsQuery);

      const publicProfiles = usersSnapshot.docs.filter(
        (doc) => doc.data().publicProfile === true,
      ).length;

      const completedProfiles = usersSnapshot.docs.filter((doc) => {
        const userData = doc.data();
        return calculateProfileCompletion(userData) >= 80;
      }).length;

      const now = new Date();
      const thisMonth = now.getMonth();
      const thisYear = now.getFullYear();
      const lastMonth = thisMonth === 0 ? 11 : thisMonth - 1;
      const lastMonthYear = thisMonth === 0 ? thisYear - 1 : thisYear;

      const thisMonthUsers = usersSnapshot.docs.filter((doc) => {
        const createdAt = doc.data().createdAt?.toDate();
        return (
          createdAt &&
          createdAt.getMonth() === thisMonth &&
          createdAt.getFullYear() === thisYear
        );
      }).length;

      const lastMonthUsers = usersSnapshot.docs.filter((doc) => {
        const createdAt = doc.data().createdAt?.toDate();
        return (
          createdAt &&
          createdAt.getMonth() === lastMonth &&
          createdAt.getFullYear() === lastMonthYear
        );
      }).length;

      // Calculate messages stats
      const unreadMessages = messagesSnapshot.docs.filter(
        (doc) => !doc.data().read,
      ).length;

      const thisMonthMessages = messagesSnapshot.docs.filter((doc) => {
        const createdAt = doc.data().createdAt?.toDate();
        return (
          createdAt &&
          createdAt.getMonth() === thisMonth &&
          createdAt.getFullYear() === thisYear
        );
      }).length;

      // Calculate store stats
      const completedOrders = ordersSnapshot.docs.filter(
        (doc) => doc.data().status === 'completed',
      ).length;

      const pendingOrders = ordersSnapshot.docs.filter(
        (doc) => doc.data().status === 'pending',
      ).length;

      const totalSales = ordersSnapshot.docs.reduce((total, doc) => {
        const orderData = doc.data();
        return (
          total + (orderData.status === 'completed' ? orderData.amount || 0 : 0)
        );
      }, 0);

      setStats({
        totalUsers: usersSnapshot.size,
        publicProfiles,
        totalServices: servicesSnapshot.size,
        totalEvents: eventsSnapshot.size,
        totalPosts: postsSnapshot.size,
        activeInvitations: invitationsSnapshot.size,
        pendingRequests: requestsSnapshot.size,
        totalOrders: ordersSnapshot.size,
        messages: {
          total: messagesSnapshot.size,
          unread: unreadMessages,
          thisMonth: thisMonthMessages,
        },
        store: {
          totalSales,
          activeListings: storeListingsSnapshot.size,
          completedOrders,
          pendingOrders,
        },
        membershipGrowth: {
          thisMonth: thisMonthUsers,
          lastMonth: lastMonthUsers,
        },
        engagement: {
          activeUsers: publicProfiles,
          completedProfiles,
        },
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  if (!currentUser?.isAdmin) {
    return (
      <div className="error-message">
        You do not have permission to access the admin dashboard.
      </div>
    );
  }

  const renderCharts = () => {
    const membershipData = {
      labels: ['Last Month', 'This Month'],
      datasets: [
        {
          label: 'New Members',
          data: [
            stats.membershipGrowth.lastMonth,
            stats.membershipGrowth.thisMonth,
          ],
          backgroundColor: 'rgba(0, 240, 255, 0.5)',
          borderColor: '#00f0ff',
          borderWidth: 2,
        },
      ],
    };

    const engagementData = {
      labels: ['Public Profiles', 'Private Profiles', 'Completed Profiles'],
      datasets: [
        {
          data: [
            stats.publicProfiles,
            stats.totalUsers - stats.publicProfiles,
            stats.engagement.completedProfiles,
          ],
          backgroundColor: [
            'rgba(0, 240, 255, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(75, 192, 192, 0.6)',
          ],
          borderColor: ['#00f0ff', '#ff6384', '#4bc0c0'],
          borderWidth: 1,
        },
      ],
    };

    const activityData = {
      labels: ['Posts', 'Events', 'Services'],
      datasets: [
        {
          label: 'Platform Activity',
          data: [stats.totalPosts, stats.totalEvents, stats.totalServices],
          backgroundColor: 'rgba(0, 240, 255, 0.2)',
          borderColor: '#00f0ff',
          borderWidth: 1,
        },
      ],
    };

    return (
      <div className="admin-charts">
        <div className="chart-container">
          <h3>Membership Growth</h3>
          <Bar
            data={membershipData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                  ticks: {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                },
                x: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                },
              },
            }}
          />
        </div>

        <div className="chart-container">
          <h3>Profile Distribution</h3>
          <Doughnut
            data={engagementData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                },
              },
            }}
          />
        </div>

        <div className="chart-container">
          <h3>Platform Activity</h3>
          <Bar
            data={activityData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                  ticks: {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                },
                x: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                },
              },
            }}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (!selectedOption) {
      return (
        <div className="admin-home">
          <div className="admin-stats">
            <div className="stat-card">
              <h3>Total Members</h3>
              <p>{stats.totalUsers}</p>
              <div className="stat-details">
                <span>New this month: +{stats.membershipGrowth.thisMonth}</span>
                <span>Last month: +{stats.membershipGrowth.lastMonth}</span>
              </div>
            </div>

            <div className="stat-card">
              <h3>Public Profiles</h3>
              <p>{stats.publicProfiles}</p>
              <div className="stat-details">
                <span>
                  {((stats.publicProfiles / stats.totalUsers) * 100).toFixed(1)}
                  % of total
                </span>
                <span>Completed: {stats.engagement.completedProfiles}</span>
              </div>
            </div>

            <div className="stat-card">
              <h3>Platform Activity</h3>
              <p>{stats.totalPosts + stats.totalEvents}</p>
              <div className="stat-details">
                <span>Posts: {stats.totalPosts}</span>
                <span>Events: {stats.totalEvents}</span>
              </div>
            </div>

            <div className="stat-card">
              <h3>Services Listed</h3>
              <p>{stats.totalServices}</p>
              <div className="stat-details">
                <span>Active Listings</span>
              </div>
            </div>

            <div className="stat-card">
              <h3>Messages</h3>
              <p>{stats.messages.total}</p>
              <div className="stat-details">
                <span className="highlight">
                  {stats.messages.unread} unread
                </span>
                <span>{stats.messages.thisMonth} this month</span>
              </div>
            </div>

            <div className="stat-card">
              <h3>Store Activity</h3>
              <p>${stats.store.totalSales.toLocaleString()}</p>
              <div className="stat-details">
                <span>{stats.store.activeListings} active listings</span>
                <span>{stats.store.completedOrders} completed orders</span>
                <span className="highlight">
                  {stats.store.pendingOrders} pending orders
                </span>
              </div>
            </div>
          </div>
          {renderCharts()}
        </div>
      );
    }

    switch (selectedOption) {
      case 'users':
        return <UserManagement />;
      case 'events':
        return <EventManagement />;
      case 'posts':
        return <PostManagement />;
      case 'content':
        return <PageContentManagement />;
      case 'catalogs':
        return <CatalogManagement />;
      case 'badges':
        return <BadgeManagement />;
      case 'feedback':
        if (!currentUser?.isAdmin) {
          return (
            <div className="access-denied">
              <h3>Access Denied</h3>
              <p>You need admin privileges to access this section.</p>
            </div>
          );
        }
        return <FeedbackManagement />;
      case 'invitations':
        return <InvitationRequestManagement />;
      case 'orders':
        return <OrderManagement />;
      case 'listings':
        return <ListingManagement />;
      default:
        return null;
    }
  };

  return (
    <div className="admin-dashboard">
      <h1 className="page-title">Admin Dashboard</h1>
      <div className="admin-nav">
        <button
          onClick={() => setSelectedOption(null)}
          className={`btn ${!selectedOption ? 'active' : ''}`}
        >
          Dashboard Home
        </button>
        <button
          onClick={() => setSelectedOption('users')}
          className={`btn ${selectedOption === 'users' ? 'active' : ''}`}
        >
          Manage Users
        </button>
        <button
          onClick={() => setSelectedOption('events')}
          className={`btn ${selectedOption === 'events' ? 'active' : ''}`}
        >
          Manage Events
        </button>
        <button
          onClick={() => setSelectedOption('posts')}
          className={`btn ${selectedOption === 'posts' ? 'active' : ''}`}
        >
          Manage Posts
        </button>
        <button
          onClick={() => setSelectedOption('content')}
          className={`btn ${selectedOption === 'content' ? 'active' : ''}`}
        >
          Manage Page Content
        </button>
        <button
          onClick={() => setSelectedOption('catalogs')}
          className={`btn ${selectedOption === 'catalogs' ? 'active' : ''}`}
        >
          Manage Catalogs
        </button>
        <button
          onClick={() => setSelectedOption('badges')}
          className={`btn ${selectedOption === 'badges' ? 'active' : ''}`}
        >
          Manage Badges
        </button>
        <button
          onClick={() => setSelectedOption('feedback')}
          className={`btn ${selectedOption === 'feedback' ? 'active' : ''}`}
        >
          Manage Feedback
        </button>
        <button
          onClick={() => setSelectedOption('invitations')}
          className={`btn ${selectedOption === 'invitations' ? 'active' : ''}`}
        >
          Manage Invitations
        </button>
        <button
          onClick={() => setSelectedOption('orders')}
          className={`btn ${selectedOption === 'orders' ? 'active' : ''}`}
        >
          Manage Orders
        </button>
        <button
          onClick={() => setSelectedOption('listings')}
          className={`btn ${selectedOption === 'listings' ? 'active' : ''}`}
        >
          Manage Listings
        </button>
      </div>
      <div className="admin-content">{renderContent()}</div>
    </div>
  );
}

export default Admin;
