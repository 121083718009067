import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  getDoc,
} from 'firebase/firestore';
import { CATEGORIES } from '../constants/categories';
import '../styles/ListingManagement.css';
import {
  FaEye,
  FaToggleOn,
  FaToggleOff,
  FaTrash,
  FaEdit,
  FaPencilAlt,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import ServiceFormModal from './ServiceFormModal'; // Import the service form modal

function ListingManagement() {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [editingId, setEditingId] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of listings per page
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    fetchListings();
  }, []);

  const fetchListings = async () => {
    try {
      const servicesRef = collection(db, 'services');
      const querySnapshot = await getDocs(servicesRef);

      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersMap = new Map(
        usersSnapshot.docs.map((doc) => [doc.id, doc.data()]),
      );

      const listingsPromises = querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        const listing = {
          id: docSnapshot.id,
          title: data.title || 'Untitled',
          description: data.description || '',
          price: data.price || 0,
          pricingType: data.pricingType || 'fixed',
          category: data.category || '',
          deliveryTime: formatDeliveryTime(data.deliveryTime),
          isActive: data.isActive ?? true,
          createdAt: data.createdAt || null,
          userId: data.userId || null,
          views: data.views || 0,
          loves: data.loves || 0,
          saves: data.saves || 0,
        };

        // Get seller data
        if (listing.userId) {
          const userData = usersMap.get(listing.userId);
          if (userData) {
            listing.seller = {
              displayName:
                userData.displayName || userData.fullName || 'Anonymous',
              photoURL: userData.photoURL || userData.profilePictureUrl,
              email: userData.email,
            };
          }
        }

        return listing;
      });

      const listings = await Promise.all(listingsPromises);
      setListings(listings);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching listings:', error);
      setLoading(false);
    }
  };

  const toggleListingStatus = async (listingId, currentStatus) => {
    try {
      const listingRef = doc(db, 'services', listingId);
      await updateDoc(listingRef, {
        isActive: !currentStatus,
      });
      fetchListings();
    } catch (error) {
      console.error('Error updating listing status:', error);
    }
  };

  const deleteListing = async (listingId) => {
    if (window.confirm('Are you sure you want to delete this listing?')) {
      try {
        const listingRef = doc(db, 'services', listingId);
        await deleteDoc(listingRef);
        fetchListings();
      } catch (error) {
        console.error('Error deleting listing:', error);
      }
    }
  };

  const formatPrice = (listing) => {
    if (!listing) return '$0.00';
    if (listing.pricingType === 'on_request') return 'Price on request';
    if (!listing.price) return '$0.00';

    const price =
      typeof listing.price === 'string'
        ? parseFloat(listing.price)
        : listing.price;

    return isNaN(price) ? '$0.00' : `$${price.toFixed(2)}`;
  };

  const formatDeliveryTime = (time) => {
    if (!time) return 'Not specified';

    const timeMap = {
      '1d': '1 Day',
      '2d': '2 Days',
      '3d': '3 Days',
      '5d': '5 Days',
      '7d': '1 Week',
      '14d': '2 Weeks',
      '30d': '1 Month',
    };

    return timeMap[time] || time;
  };

  const handleCategoryChange = async (listingId, newCategory) => {
    try {
      await updateDoc(doc(db, 'services', listingId), {
        category: newCategory,
        updatedAt: new Date(),
      });

      setListings(
        listings.map((listing) =>
          listing.id === listingId
            ? { ...listing, category: newCategory }
            : listing,
        ),
      );

      setEditingCategory(null);
      toast.success('Category updated successfully');
    } catch (error) {
      console.error('Error updating category:', error);
      toast.error('Failed to update category');
    }
  };

  const handleEditClick = async (serviceId) => {
    try {
      const serviceDoc = await getDoc(doc(db, 'services', serviceId));
      if (serviceDoc.exists()) {
        setSelectedService({
          id: serviceDoc.id,
          ...serviceDoc.data(),
        });
        setShowEditModal(true);
      }
    } catch (error) {
      console.error('Error fetching service details:', error);
      toast.error('Failed to load service details');
    }
  };

  const handleEditSubmit = async (updatedService) => {
    try {
      await updateDoc(doc(db, 'services', selectedService.id), updatedService);
      setShowEditModal(false);
      setSelectedService(null);
      fetchListings(); // Refresh the listings
      toast.success('Service updated successfully');
    } catch (error) {
      console.error('Error updating service:', error);
      toast.error('Failed to update service');
    }
  };

  const filteredListings = listings.filter((listing) => {
    const matchesSearch =
      listing.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.seller?.displayName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());

    const matchesCategory =
      filterCategory === 'all' || listing.category === filterCategory;

    return matchesSearch && matchesCategory;
  });

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentListings = filteredListings.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );
  const totalPages = Math.ceil(filteredListings.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to top of listings
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return <div className="loading-state">Loading listings...</div>;
  }

  return (
    <div className="listing-management">
      <div className="management-header">
        <h2>Service Listings</h2>
        <div className="management-controls">
          <input
            type="text"
            placeholder="Search listings..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            className="filter-select"
          >
            <option value="all">All Categories</option>
            {Object.keys(CATEGORIES).map((key) => (
              <option key={key} value={key}>
                {CATEGORIES[key]}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="listings-grid">
        {currentListings.map((listing) => (
          <div key={listing.id} className="listing-card">
            <img
              src={listing.seller?.photoURL || '/default-avatar.png'}
              alt={listing.seller?.displayName || 'Unknown Seller'}
              className="seller-avatar"
            />

            <div className="listing-info">
              <h3 className="listing-title">{listing.title}</h3>

              <div className="listing-meta">
                <div className="meta-item">
                  <span className="meta-label">Category:</span>
                  {editingCategory === listing.id ? (
                    <select
                      value={listing.category || ''}
                      onChange={(e) =>
                        handleCategoryChange(listing.id, e.target.value)
                      }
                      onBlur={() => setEditingCategory(null)}
                      className="category-select"
                      autoFocus
                    >
                      <option value="">Select Category</option>
                      {Object.entries(CATEGORIES).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <span
                      className="category-label"
                      onClick={() => setEditingCategory(listing.id)}
                    >
                      {CATEGORIES[listing.category] || 'Uncategorized'}
                    </span>
                  )}
                </div>

                <div className="meta-item">
                  <span className="meta-label">Price:</span>
                  <span>{formatPrice(listing)}</span>
                </div>

                <div className="meta-item">
                  <span className="meta-label">Delivery:</span>
                  <span>{listing.deliveryTime}</span>
                </div>

                <div className="meta-item">
                  <span className="meta-label">Seller:</span>
                  <span>{listing.seller?.displayName || 'Unknown'}</span>
                </div>
              </div>
            </div>

            <div className="listing-actions">
              <button
                className="action-btn btn-view"
                onClick={() => window.open(`/service/${listing.id}`, '_blank')}
                title="View Service"
              >
                <FaEye />
              </button>

              <button
                className={`action-btn btn-toggle ${
                  listing.isActive ? 'active' : ''
                }`}
                onClick={() =>
                  toggleListingStatus(listing.id, listing.isActive)
                }
                title={listing.isActive ? 'Deactivate' : 'Activate'}
              >
                {listing.isActive ? <FaToggleOn /> : <FaToggleOff />}
              </button>

              <button
                className="action-btn btn-delete"
                onClick={() => deleteListing(listing.id)}
                title="Delete Service"
              >
                <FaTrash />
              </button>

              <button
                className="action-btn btn-edit"
                onClick={() => handleEditClick(listing.id)}
                title="Edit Service"
              >
                <FaPencilAlt />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      {filteredListings.length > itemsPerPage && (
        <div className="pagination-controls">
          <button
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
            className="page-btn first"
          >
            First
          </button>

          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="page-btn prev"
          >
            Previous
          </button>

          <div className="page-numbers">
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter((num) => {
                // Show current page, first page, last page, and pages around current
                return (
                  num === 1 ||
                  num === totalPages ||
                  Math.abs(currentPage - num) <= 2
                );
              })
              .map((number, index, array) => (
                <React.Fragment key={number}>
                  {index > 0 && array[index - 1] !== number - 1 && (
                    <span className="page-ellipsis">...</span>
                  )}
                  <button
                    className={`page-btn number ${
                      currentPage === number ? 'active' : ''
                    }`}
                    onClick={() => handlePageChange(number)}
                  >
                    {number}
                  </button>
                </React.Fragment>
              ))}
          </div>

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="page-btn next"
          >
            Next
          </button>

          <button
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
            className="page-btn last"
          >
            Last
          </button>
        </div>
      )}

      <div className="pagination-info">
        Showing {indexOfFirstItem + 1} to{' '}
        {Math.min(indexOfLastItem, filteredListings.length)} of{' '}
        {filteredListings.length} listings
      </div>

      {/* Edit Service Modal */}
      {showEditModal && selectedService && (
        <ServiceFormModal
          initialData={selectedService}
          onSubmit={handleEditSubmit}
          onClose={() => {
            setShowEditModal(false);
            setSelectedService(null);
          }}
          isEditing={true}
        />
      )}
    </div>
  );
}

export default ListingManagement;
