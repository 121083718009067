import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  collection,
  query,
  orderBy,
  getDocs,
  updateDoc,
  doc,
} from 'firebase/firestore';
import '../styles/OrderManagement.css';
import { FaCopy } from 'react-icons/fa';

function OrderManagement() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all'); // 'all', 'pending', 'processing', 'completed', 'cancelled'

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const ordersQuery = query(
        collection(db, 'orders'),
        orderBy('createdAt', 'desc'),
      );
      const querySnapshot = await getDocs(ordersQuery);

      const ordersData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          status: data.status || 'pending', // Ensure status is always set
          createdAt: data.createdAt?.toDate(),
        };
      });

      setOrders(ordersData);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch orders');
      console.error('Error fetching orders:', err);
      setLoading(false);
    }
  };

  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      const orderRef = doc(db, 'orders', orderId);

      // Update Firestore
      await updateDoc(orderRef, {
        status: newStatus,
        updatedAt: new Date(),
      });

      // Update local state
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId
            ? {
                ...order,
                status: newStatus,
                updatedAt: new Date(),
              }
            : order,
        ),
      );

      // Optionally, refetch orders to ensure sync
      await fetchOrders();
    } catch (err) {
      setError('Failed to update order status');
      console.error('Error updating order status:', err);
    }
  };

  const getStatusColor = (status) => {
    // Convert status to lowercase for consistent comparison
    const normalizedStatus = (status || 'pending').toLowerCase();
    switch (normalizedStatus) {
      case 'pending':
        return 'status-pending';
      case 'processing':
        return 'status-processing';
      case 'completed':
        return 'status-completed';
      case 'cancelled':
        return 'status-cancelled';
      default:
        return 'status-pending';
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const filteredOrders = orders.filter((order) =>
    filter === 'all' ? true : order.status === filter,
  );

  const copyToClipboard = (text, label) => {
    navigator.clipboard.writeText(text);
    // Optional: Show a small tooltip or notification
    alert(`${label} copied to clipboard!`);
  };

  if (loading) return <div className="loading">Loading orders...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="order-management">
      <h2>Manage Orders</h2>
      <div className="filter-controls">
        <button
          className={`filter-btn ${filter === 'all' ? 'active' : ''}`}
          onClick={() => setFilter('all')}
        >
          All Orders
        </button>
        <button
          className={`filter-btn ${filter === 'pending' ? 'active' : ''}`}
          onClick={() => setFilter('pending')}
        >
          Pending
        </button>
        <button
          className={`filter-btn ${filter === 'processing' ? 'active' : ''}`}
          onClick={() => setFilter('processing')}
        >
          Processing
        </button>
        <button
          className={`filter-btn ${filter === 'completed' ? 'active' : ''}`}
          onClick={() => setFilter('completed')}
        >
          Completed
        </button>
        <button
          className={`filter-btn ${filter === 'cancelled' ? 'active' : ''}`}
          onClick={() => setFilter('cancelled')}
        >
          Cancelled
        </button>
      </div>

      {filteredOrders.length === 0 ? (
        <div className="no-orders">
          No {filter !== 'all' ? filter : ''} orders found
        </div>
      ) : (
        <div className="table-container">
          <table className="orders-table">
            <thead>
              <tr>
                <th>Order & Customer Details</th>
                <th>Items</th>
                <th>Price</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order) => (
                <tr key={order.id}>
                  <td>
                    <div className="combined-details">
                      <div className="order-details">
                        <div
                          className="order-id clickable"
                          onClick={() => copyToClipboard(order.id, 'Order ID')}
                        >
                          #{order.id} <FaCopy className="copy-icon" />
                        </div>
                        <div className="order-date">
                          {formatDate(order.createdAt)}
                        </div>
                      </div>
                      <div className="customer-details">
                        <div
                          className="customer-name clickable"
                          onClick={() =>
                            copyToClipboard(
                              order.customerDetails?.name,
                              'Customer name',
                            )
                          }
                        >
                          {order.customerDetails?.name}{' '}
                          <FaCopy className="copy-icon" />
                        </div>
                        <div
                          className="customer-email clickable"
                          onClick={() =>
                            copyToClipboard(
                              order.customerDetails?.email,
                              'Email',
                            )
                          }
                        >
                          {order.customerDetails?.email}{' '}
                          <FaCopy className="copy-icon" />
                        </div>
                        <div
                          className="customer-phone clickable"
                          onClick={() =>
                            copyToClipboard(
                              order.customerDetails?.phone,
                              'Phone',
                            )
                          }
                        >
                          {order.customerDetails?.phone}{' '}
                          <FaCopy className="copy-icon" />
                        </div>
                        <div
                          className="customer-address clickable"
                          onClick={() =>
                            copyToClipboard(
                              order.customerDetails?.address,
                              'Address',
                            )
                          }
                        >
                          {order.customerDetails?.address}{' '}
                          <FaCopy className="copy-icon" />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="items-container">
                      {order.items?.map((item, index) => (
                        <div key={index} className="item-row">
                          <div className="item-info">
                            <span className="item-name">{item.name}</span>
                            <span className="item-variant">
                              {item.selectedVariants?.size} /{' '}
                              {item.selectedVariants?.color}
                            </span>
                            <span className="item-qty">
                              ×{item.quantity || 1}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td>LKR {order.total}</td>
                  <td className="status-cell">
                    <div
                      className={`status-badge ${getStatusColor(order.status)}`}
                    >
                      {order.status ? order.status.toUpperCase() : 'PENDING'}
                    </div>
                  </td>
                  <td>
                    <div className="table-actions">
                      {order.status === 'pending' && (
                        <button
                          className="btn-process"
                          onClick={() =>
                            updateOrderStatus(order.id, 'processing')
                          }
                        >
                          Process
                        </button>
                      )}
                      {order.status === 'processing' && (
                        <button
                          className="btn-complete"
                          onClick={() =>
                            updateOrderStatus(order.id, 'completed')
                          }
                        >
                          Complete
                        </button>
                      )}
                      {(order.status === 'pending' ||
                        order.status === 'processing') && (
                        <button
                          className="btn-cancel"
                          onClick={() =>
                            updateOrderStatus(order.id, 'cancelled')
                          }
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default OrderManagement;
