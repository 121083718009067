export const CATEGORIES = {
  web_development: 'Web Development',
  mobile_development: 'Mobile Development',
  design: 'Design',
  software_development: 'Software Development',
  game_development: 'Game Development',
  blockchain: 'Blockchain & Cryptocurrency',
  ui_design: 'UI Design',
  ux_design: 'UX Design',
  graphic_design: 'Graphic Design',
  logo_design: 'Logo Design',
  illustration: 'Illustration',
  digital_marketing: 'Digital Marketing',
  seo: 'SEO & SEM',
  email_marketing: 'Email Marketing',
  content_marketing: 'Content Marketing',
  copywriting: 'Copywriting',
  technical_writing: 'Technical Writing',
  translation: 'Translation',
  video_production: 'Video Production',
  animation: 'Animation',
  voice_over: 'Voice Over',
  business_strategy: 'Business Strategy',
  financial_consulting: 'Financial Consulting',
  legal_consulting: 'Legal Consulting',
  data_analysis: 'Data Analysis',
  data_science: 'Data Science',
  machine_learning: 'Machine Learning & AI',
  social_media_management: 'Social Media Management',
  influencer_marketing: 'Influencer Marketing',
  community_management: 'Community Management',
  virtual_assistant: 'Virtual Assistant',
  customer_service: 'Customer Service',
  transcription: 'Transcription',
  cybersecurity: 'Cybersecurity',
  cloud_services: 'Cloud Services',
  devops: 'DevOps & Infrastructure',
  other: 'Other Services',
};
