import React from 'react';
import { Helmet } from 'react-helmet-async';

function SkillExplorationSEO() {
  const baseUrl = process.env.REACT_APP_BASE_URL || 'https://oecsl.com';
  const ogImagePath = '/images/skills.png';

  return (
    <Helmet>
      <title>
        Explore Professional Skills & Connect with Expert Freelancers | OECSL
      </title>
      <meta
        name="title"
        content="Explore Professional Skills & Connect with Expert Freelancers | OECSL"
      />
      <meta
        name="description"
        content="Discover skilled professionals across development, design, marketing, and more. Connect with verified freelancers, view portfolios, and collaborate on projects."
      />

      {/* Override Open Graph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${baseUrl}/skills`} />
      <meta
        property="og:title"
        content="Find Expert Freelancers & Showcase Your Skills | OECSL"
      />
      <meta
        property="og:description"
        content="Connect with verified professionals, explore portfolios, and find the perfect match for your projects."
      />
      <meta property="og:image" content={`${baseUrl}${ogImagePath}`} />

      {/* Override Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={`${baseUrl}/skills`} />
      <meta
        name="twitter:title"
        content="Expert Freelancers & Professional Skills | OECSL"
      />
      <meta
        name="twitter:description"
        content="Discover top talent across development, design, marketing, and more."
      />
      <meta name="twitter:image" content={`${baseUrl}${ogImagePath}`} />

      <link rel="canonical" href={`${baseUrl}/skills`} />
    </Helmet>
  );
}

export default SkillExplorationSEO;
