import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import {
  collection,
  query,
  getDocs,
  orderBy,
  deleteDoc,
  doc,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  FaTrash,
  FaEdit,
  FaEnvelope,
  FaCalendar,
  FaTag,
  FaDollarSign,
  FaClock,
} from 'react-icons/fa';
import ContactModal from './ContactModal';
import { CATEGORIES } from '../constants/categories';
import { useNavigate } from 'react-router-dom';
import LoginPromptModal from './LoginPromptModal';

function ServiceRequests() {
  const { currentUser } = useAuth();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'buyerRequests'),
        orderBy('createdAt', 'desc'),
      );
      const snapshot = await getDocs(q);
      const requestsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
      }));
      setRequests(requestsData);
    } catch (error) {
      console.error('Error fetching requests:', error);
      setError('Failed to load requests. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (requestId) => {
    if (!window.confirm('Are you sure you want to delete this request?'))
      return;

    try {
      await deleteDoc(doc(db, 'buyerRequests', requestId));
      setRequests(requests.filter((request) => request.id !== requestId));
    } catch (error) {
      console.error('Error deleting request:', error);
      alert('Failed to delete request. Please try again.');
    }
  };

  const handleContact = (request) => {
    if (!currentUser) {
      setShowLoginPrompt(true);
      return;
    }
    setSelectedRequest(request);
    setShowContactModal(true);
  };

  const formatDate = (date) => {
    if (!date) return 'No date';
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(date);
  };

  // Convert CATEGORIES object to array if needed
  const categoriesArray = Object.entries(CATEGORIES).map(([id, name]) => ({
    id,
    name,
  }));

  const getCategoryName = (categoryId) => {
    const category = categoriesArray.find((cat) => cat.id === categoryId);
    return category ? category.name : 'Uncategorized';
  };

  if (loading) {
    return <div className="loading">Loading requests...</div>;
  }

  if (error) {
    return (
      <div className="error-state">
        <p>{error}</p>
        <button onClick={fetchRequests}>Try Again</button>
      </div>
    );
  }

  return (
    <div className="requests-container">
      <div className="requests-header">
        <h2>Service Requests</h2>
        <p>Browse the latest requests from buyers</p>
      </div>

      <div className="requests-feed">
        {requests.length === 0 ? (
          <div className="no-requests">
            <h3>No requests found</h3>
            <p>There are currently no service requests available.</p>
          </div>
        ) : (
          requests.map((request) => (
            <div key={request.id} className="request-row">
              <div className="request-main">
                <div className="request-title-section">
                  <div className="title-wrapper">
                    <h3>{request.title}</h3>
                    <span className="request-category">
                      <FaTag /> {getCategoryName(request.category)}
                    </span>
                  </div>
                </div>
                <p className="request-description">{request.description}</p>
              </div>

              <div className="request-details">
                <div className="detail-item">
                  <FaDollarSign />
                  <span>
                    {request.budget ? `$${request.budget}` : 'Flexible'}
                  </span>
                </div>
                <div className="detail-item">
                  <FaClock />
                  <span>{request.duration || 'Not specified'}</span>
                </div>
                <div className="detail-item">
                  <FaCalendar />
                  <span>{formatDate(request.createdAt)}</span>
                </div>
              </div>

              <div className="request-actions">
                {currentUser?.uid === request.userId ? (
                  <>
                    <button
                      className="btn btn-small"
                      onClick={() => handleDelete(request.id)}
                      title="Delete request"
                    >
                      <FaTrash /> Delete
                    </button>
                    <button
                      className="btn btn-small"
                      onClick={() => navigate(`/edit-request/${request.id}`)}
                      title="Edit request"
                    >
                      <FaEdit /> Edit
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => handleContact(request)}
                    title="Contact buyer"
                  >
                    <FaEnvelope /> Contact Buyer
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>

      {showLoginPrompt && (
        <LoginPromptModal onClose={() => setShowLoginPrompt(false)} />
      )}

      {showContactModal && selectedRequest && (
        <ContactModal
          request={selectedRequest}
          onSubmit={async (message) => {
            try {
              await addDoc(collection(db, 'messages'), {
                requestId: selectedRequest.id,
                buyerId: selectedRequest.userId,
                sellerId: currentUser.uid,
                message,
                createdAt: serverTimestamp(),
                read: false,
              });
              setShowContactModal(false);
            } catch (error) {
              console.error('Error sending message:', error);
              alert('Failed to send message. Please try again.');
            }
          }}
          onClose={() => {
            setShowContactModal(false);
            setSelectedRequest(null);
          }}
        />
      )}
    </div>
  );
}

export default ServiceRequests;
