import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaTimes, FaPlus } from 'react-icons/fa';
import { CATEGORIES } from '../constants/categories';

function RequestFormModal({ onSubmit, onClose }) {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    budget: '',
    deadline: '',
    requirements: '',
    referenceLinks: [''],
  });

  // Convert CATEGORIES object to array for mapping
  const categoryOptions = Object.entries(CATEGORIES).map(([value, label]) => ({
    value,
    label,
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="modal-overlay">
      <div className="service-form-modal">
        <form onSubmit={handleSubmit}>
          <div className="modal-header">
            <h2>Submit New Request</h2>
            <button type="button" className="close-btn" onClick={onClose}>
              <FaTimes />
            </button>
          </div>

          <div className="modal-body">
            <div className="form-section">
              <div className="form-group">
                <label htmlFor="title">Request Title</label>
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  placeholder="What do you need help with?"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="category">Category</label>
                <select
                  id="category"
                  className="form-control"
                  value={formData.category}
                  onChange={(e) =>
                    setFormData({ ...formData, category: e.target.value })
                  }
                  required
                >
                  <option value="">Select a category</option>
                  {categoryOptions.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  id="description"
                  className="form-control"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  placeholder="Describe your requirements in detail..."
                  rows="4"
                  required
                />
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="budget">Budget</label>
                  <input
                    type="number"
                    id="budget"
                    className="form-control"
                    value={formData.budget}
                    onChange={(e) =>
                      setFormData({ ...formData, budget: e.target.value })
                    }
                    placeholder="Your budget in USD"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="deadline">Deadline</label>
                  <input
                    type="date"
                    id="deadline"
                    className="form-control"
                    value={formData.deadline}
                    onChange={(e) =>
                      setFormData({ ...formData, deadline: e.target.value })
                    }
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="requirements">Additional Requirements</label>
                <textarea
                  id="requirements"
                  className="form-control"
                  value={formData.requirements}
                  onChange={(e) =>
                    setFormData({ ...formData, requirements: e.target.value })
                  }
                  placeholder="Any specific requirements or preferences..."
                  rows="3"
                />
              </div>

              <div className="form-section">
                <div className="form-group">
                  <label>Reference Links</label>
                  {formData.referenceLinks.map((link, index) => (
                    <div key={index} className="form-row">
                      <input
                        type="url"
                        className="form-control"
                        value={link}
                        onChange={(e) => {
                          const newLinks = [...formData.referenceLinks];
                          newLinks[index] = e.target.value;
                          setFormData({
                            ...formData,
                            referenceLinks: newLinks,
                          });
                        }}
                        placeholder="https://"
                      />
                      <button
                        type="button"
                        className="btn btn-icon"
                        onClick={() => {
                          const newLinks = formData.referenceLinks.filter(
                            (_, i) => i !== index,
                          );
                          setFormData({
                            ...formData,
                            referenceLinks: newLinks,
                          });
                        }}
                        disabled={formData.referenceLinks.length === 1}
                      >
                        <FaTimes />
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setFormData({
                        ...formData,
                        referenceLinks: [...formData.referenceLinks, ''],
                      });
                    }}
                  >
                    <FaPlus /> Add Reference Link
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Submit Request
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

RequestFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RequestFormModal;
