import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FaStar,
  FaHeart,
  FaRegHeart,
  FaBookmark,
  FaRegBookmark,
  FaShareAlt,
  FaEnvelope,
} from 'react-icons/fa';
import { ServiceViewModal } from './';
import LoginPromptModal from './LoginPromptModal';
import { useAuth } from '../contexts/AuthContext';

const ServiceCard = ({
  service = {
    fullName: 'Anonymous User',
    profilePictureUrl: null,
    sellerAvatar: null,
    sellerName: null,
    providerImage: null,
    providerName: null,
    rating: '0.0',
    reviewCount: '0',
    deliveryTime: '7',
    responseTime: '24',
    completedOrders: '0',
    category: 'Uncategorized',
  },
  onContactClick,
  isOwner = false,
  onEdit = () => {},
  onDelete = () => {},
  onToggleActive = () => {},
  isLoved = false,
  onLove = () => {},
  isSaved = false,
  onSave = () => {},
}) => {
  const { currentUser } = useAuth();
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  // Format description with proper truncation
  const formatDescription = () => {
    const description = service.description || '';
    const maxLength = 60; // Shorter length for card view

    if (description.length <= maxLength) return description;
    return description.substring(0, maxLength).trim() + '...';
  };

  // Get user profile data
  const getUserProfileData = () => {
    const isNewService =
      !service.completedOrders || service.completedOrders === '0';

    return {
      name:
        service.fullName ||
        service.displayName ||
        service.username ||
        'Anonymous',
      avatar:
        service.profilePictureUrl ||
        service.photoURL ||
        service.profileImageUrl ||
        `https://ui-avatars.com/api/?name=${encodeURIComponent(
          service.fullName ||
            service.displayName ||
            service.username ||
            'Anonymous',
        )}&background=0D8ABC&color=fff`,
      rating: isNewService ? 'New' : service.rating || '0.0',
      reviewCount: isNewService ? '' : service.reviewCount || '0',
      responseTime: service.responseTime || '24',
      completedOrders: service.completedOrders || '0',
    };
  };

  const profile = getUserProfileData();

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: service.title,
          text: service.description,
          url: window.location.href,
        });
      } else {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  // Handle read more click
  const handleReadMore = (e) => {
    e.stopPropagation();
    setShowServiceModal(true);
  };

  // Update the contact button click handler
  const handleContactClick = () => {
    if (!currentUser) {
      setShowLoginPrompt(true);
      return;
    }
    onContactClick();
  };

  // Update the social action handlers to show login prompt
  const handleLoveClick = () => {
    if (!currentUser) {
      setShowLoginPrompt(true);
      return;
    }
    onLove(service.id);
  };

  const handleSaveClick = () => {
    if (!currentUser) {
      setShowLoginPrompt(true);
      return;
    }
    onSave(service.id);
  };

  return (
    <>
      <div className={`service-card ${!service.isActive ? 'inactive' : ''}`}>
        <div className="service-image-container">
          <img
            src={
              service.imageUrl ||
              'https://www.callcentrehelper.com/images/stories/2016/05/available1-760.jpg'
            }
            alt={service.imageUrl ? service.title : 'Service Available'}
            className="service-image"
          />
          <div className="service-category">{service.category}</div>
        </div>

        <div className="card-content">
          <div className="seller-info">
            <img
              src={profile.avatar}
              alt={profile.name}
              className="seller-avatar"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(
                  profile.name,
                )}&background=0D8ABC&color=fff`;
              }}
            />
            <div className="seller-details">
              <span className="seller-name">{profile.name}</span>
              <div className="seller-stats">
                <span className="rating">
                  {profile.rating === 'New' ? (
                    <span className="new-badge">New</span>
                  ) : (
                    <>
                      <FaStar /> {profile.rating}
                      <span className="reviews">
                        ({profile.reviewCount} reviews)
                      </span>
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>

          <h3 className="service-title">{service.title}</h3>
          <div className="service-description">
            <p>{formatDescription()}</p>
            {service.description?.length > 60 && (
              <button className="read-more-btn" onClick={handleReadMore}>
                Read more
              </button>
            )}
          </div>

          <div className="service-details">
            <div className="detail-item">
              <span className="label">Delivery</span>
              <span className="value">{service.deliveryTime || '7'} days</span>
            </div>
            <div className="detail-item">
              <span className="label">Response</span>
              <span className="value">{service.responseTime || '24'}h</span>
            </div>
            <div className="detail-item">
              <span className="label">Orders</span>
              <span className="value">{service.completedOrders || '0'}</span>
            </div>
          </div>

          <div className="card-footer">
            <div className="price-section">
              <span className="price-label">Starting at</span>
              <span className="price-amount">
                {service.pricingType === 'on_request'
                  ? 'Price on request'
                  : `$${
                      typeof service.price === 'number'
                        ? service.price.toFixed(2)
                        : service.price
                    }`}
              </span>
            </div>

            <div className="card-actions">
              {!isOwner && (
                <>
                  <button
                    className="btn btn-primary"
                    onClick={handleContactClick}
                    title="Contact provider"
                  >
                    <FaEnvelope /> Contact
                  </button>
                  <div className="social-actions">
                    <button
                      className={`action-btn ${isLoved ? 'active' : ''}`}
                      onClick={handleLoveClick}
                      title={
                        isLoved ? 'Remove from favorites' : 'Add to favorites'
                      }
                    >
                      {isLoved ? <FaHeart /> : <FaRegHeart />}
                      <span className="love-count">
                        {service.loveCount || 0}
                      </span>
                    </button>
                    <button
                      className={`action-btn ${isSaved ? 'active' : ''}`}
                      onClick={handleSaveClick}
                      title={isSaved ? 'Remove from saved' : 'Save for later'}
                    >
                      {isSaved ? <FaBookmark /> : <FaRegBookmark />}
                    </button>
                    <button
                      className="action-btn"
                      onClick={handleShare}
                      title="Share"
                    >
                      <FaShareAlt />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showServiceModal && (
        <ServiceViewModal
          service={service}
          onClose={() => setShowServiceModal(false)}
          onContact={onContactClick}
          isOwner={isOwner}
        />
      )}

      {showLoginPrompt && (
        <LoginPromptModal onClose={() => setShowLoginPrompt(false)} />
      )}
    </>
  );
};

ServiceCard.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pricingType: PropTypes.oneOf([
      'fixed',
      'starting_from',
      'on_request',
      'hourly',
    ]),
    isActive: PropTypes.bool,
    userId: PropTypes.string.isRequired,
    category: PropTypes.string,
    // User profile fields
    fullName: PropTypes.string,
    displayName: PropTypes.string,
    username: PropTypes.string,
    profilePictureUrl: PropTypes.string,
    photoURL: PropTypes.string,
    profileImageUrl: PropTypes.string,
    // Provider/Seller details
    sellerAvatar: PropTypes.string,
    sellerName: PropTypes.string,
    providerImage: PropTypes.string,
    providerName: PropTypes.string,
    // Rating and stats
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reviewCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // Service metrics
    deliveryTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    responseTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    completedOrders: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  onContactClick: PropTypes.func.isRequired,
  isOwner: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onToggleActive: PropTypes.func,
  isLoved: PropTypes.bool,
  onLove: PropTypes.func,
  isSaved: PropTypes.bool,
  onSave: PropTypes.func,
};

export default ServiceCard;
