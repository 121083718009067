import React, { useState, useEffect, useCallback } from 'react';
import {
  getAllUsers,
  addUser,
  updateUser,
  deleteUser,
  verifyAdminStatus,
  getUserRole,
  debugAdminStatus,
} from '../services/firestoreService';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../firebase';
import '../styles/UserManagement.css';
import { toast } from 'react-toastify';
import { FaSortAmountUp, FaSortAmountDown } from 'react-icons/fa';

const calculateProfileCompletion = (user) => {
  const requiredFields = [
    'fullName',
    'email',
    'phone',
    'location',
    'skills',
    'profilePictureUrl',
    'tagline',
    'bio',
    'hourlyRate',
    'availabilityStatus',
    'yearsOfExperience',
    'languages',
    'workExperience',
    'portfolioItems',
    'countriesServed',
  ];

  const fieldWeights = {
    fullName: 10,
    email: 10,
    phone: 5,
    location: 5,
    skills: 15,
    profilePictureUrl: 10,
    tagline: 5,
    bio: 10,
    hourlyRate: 5,
    availabilityStatus: 5,
    yearsOfExperience: 5,
    languages: 5,
    workExperience: 5,
    portfolioItems: 5,
    countriesServed: 5,
  };

  let totalScore = 0;
  let totalWeight = 0;

  requiredFields.forEach((field) => {
    const weight = fieldWeights[field];
    totalWeight += weight;

    if (field === 'skills') {
      const hasSkills =
        user[field] && Object.values(user[field]).flat().length > 0;
      if (hasSkills) totalScore += weight;
    } else if (field === 'languages') {
      const hasLanguages = user[field] && user[field].length > 0;
      if (hasLanguages) totalScore += weight;
    } else if (field === 'workExperience') {
      const hasExperience = user[field] && user[field].length > 0;
      if (hasExperience) totalScore += weight;
    } else if (field === 'portfolioItems') {
      const hasPortfolio = user[field] && user[field].length > 0;
      if (hasPortfolio) totalScore += weight;
    } else if (field === 'countriesServed') {
      const hasCountries = user[field] && user[field].length > 0;
      if (hasCountries) totalScore += weight;
    } else if (user[field] && user[field].toString().trim() !== '') {
      totalScore += weight;
    }
  });

  return Math.round((totalScore / totalWeight) * 100);
};

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortBy, setSortBy] = useState('registeredDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const usersPerPage = 20;
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    isAdmin: false,
    isModerator: false,
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const checkAdmin = async () => {
      await debugAdminStatus();
    };
    checkAdmin();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      const userList = await getAllUsers();
      setUsers(userList);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to fetch users. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await addUser({
        email: newUser.email,
        isAdmin: newUser.isAdmin,
        isModerator: newUser.isModerator,
      });
      setNewUser({
        email: '',
        password: '',
        isAdmin: false,
        isModerator: false,
      });
      await fetchUsers();
      handleCloseAddUserForm();
    } catch (err) {
      console.error('Error adding user:', err);
      setError('Failed to add user. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleAdminStatus = async (userId, currentStatus) => {
    setLoading(true);
    setError(null);
    try {
      await updateUser(userId, { isAdmin: !currentStatus });
      await fetchUsers();
    } catch (err) {
      console.error('Error updating user status:', err);
      setError('Failed to update user status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleModeratorStatus = async (userId, currentStatus) => {
    setLoading(true);
    setError(null);
    try {
      await updateUser(userId, { isModerator: !currentStatus });
      await fetchUsers();
    } catch (err) {
      console.error('Error updating moderator status:', err);
      setError('Failed to update moderator status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      if (!window.confirm('Are you sure you want to delete this user?')) {
        return;
      }

      const currentUser = auth.currentUser;
      console.log('Current user in component:', currentUser?.uid);

      const adminDoc = await getDoc(doc(db, 'users', currentUser.uid));
      console.log('Admin doc in component:', adminDoc.data());

      if (!adminDoc.exists() || !adminDoc.data()?.isAdmin) {
        console.log('Not an admin, but trying anyway...');
      }

      setLoading(true);
      await deleteUser(userId);
      await fetchUsers();
      toast.success('User deleted successfully');
    } catch (error) {
      console.error('Error in component:', error);
      toast.error(error.message || 'Failed to delete user');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAddUserForm = () => {
    setShowAddUserForm(false);
    setNewUser({ email: '', password: '', isAdmin: false, isModerator: false });
  };

  const sortUsers = useCallback(
    (users) => {
      return [...users].sort((a, b) => {
        if (sortBy === 'registeredDate') {
          const dateA = new Date(a.registeredAt || 0);
          const dateB = new Date(b.registeredAt || 0);
          return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
        } else {
          const rateA = calculateProfileCompletion(a);
          const rateB = calculateProfileCompletion(b);
          return sortOrder === 'desc' ? rateB - rateA : rateA - rateB;
        }
      });
    },
    [sortBy, sortOrder],
  );

  const filteredUsers = users.filter((user) => {
    const searchLower = searchTerm.toLowerCase();
    const matchesSearch =
      user.email?.toLowerCase().includes(searchLower) ||
      user.name?.toLowerCase().includes(searchLower) ||
      user.phone?.includes(searchTerm);

    const matchesFilter =
      statusFilter === 'all'
        ? true
        : statusFilter === 'admin'
        ? user.isAdmin
        : statusFilter === 'mod'
        ? user.isModerator
        : true;

    return matchesSearch && matchesFilter;
  });

  const sortedAndFilteredUsers = sortUsers(filteredUsers);

  const totalPages = Math.ceil(sortedAndFilteredUsers.length / usersPerPage);
  const paginatedUsers = sortedAndFilteredUsers.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage,
  );

  const handlePublicProfileToggle = async (userId, isPublic) => {
    try {
      await updateDoc(doc(db, 'users', userId), {
        publicProfile: isPublic,
        updatedAt: serverTimestamp(),
      });

      // Update local state
      setUsers(
        users.map((user) =>
          user.id === userId ? { ...user, publicProfile: isPublic } : user,
        ),
      );

      toast.success(`Profile visibility ${isPublic ? 'enabled' : 'disabled'}`);
    } catch (error) {
      console.error('Error updating profile visibility:', error);
      toast.error('Failed to update profile visibility');
    }
  };

  const toggleUserVisibility = async (userId, currentVisibility) => {
    try {
      // Debug: Log the current state
      console.log('Current visibility state:', {
        userId,
        currentVisibility,
      });

      const updates = {
        publicProfile: !currentVisibility,
        updatedAt: serverTimestamp(),
        updatedBy: auth.currentUser.uid,
        profileCompletion: calculateProfileCompletion(
          users.find((u) => u.id === userId),
        ),
      };

      console.log('Updating user with:', updates);

      await updateDoc(doc(db, 'users', userId), updates);

      // Update local state
      setUsers(
        users.map((user) =>
          user.id === userId ? { ...user, ...updates } : user,
        ),
      );

      toast.success(
        `Profile visibility ${!currentVisibility ? 'enabled' : 'disabled'}`,
      );
    } catch (error) {
      console.error('Error updating user visibility:', error);
      toast.error('Failed to update user visibility');
    }
  };

  return (
    <div className="user-management">
      <div className="management-header">
        <div className="header-left">
          <h2>Users</h2>
          <div className="quick-stats">
            <span>{users.length} Total</span>
            <span>{users.filter((u) => u.isAdmin).length} Admins</span>
            <span>{users.filter((u) => u.isModerator).length} Mods</span>
          </div>
        </div>
        <button
          className="add-user-btn"
          onClick={() => setShowAddUserForm(true)}
        >
          <i className="fa-solid fa-user-plus"></i>
        </button>
      </div>

      <div className="controls-bar">
        <div className="search-box">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
        <select
          value={statusFilter}
          onChange={(e) => {
            setStatusFilter(e.target.value);
            setCurrentPage(1);
          }}
          className="status-select"
        >
          <option value="all">All Users</option>
          <option value="admin">Admins</option>
          <option value="mod">Moderators</option>
        </select>
        <div className="sort-controls">
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="sort-select"
          >
            <option value="registeredDate">Registration Date</option>
            <option value="completionRate">Profile Completion</option>
          </select>

          <button
            className="sort-order-btn"
            onClick={() =>
              setSortOrder((order) => (order === 'asc' ? 'desc' : 'asc'))
            }
          >
            {sortOrder === 'asc' ? <FaSortAmountUp /> : <FaSortAmountDown />}
          </button>
        </div>
      </div>

      <div className="table-container">
        <table className="users-table">
          <thead>
            <tr>
              <th style={{ width: '250px' }}>Email</th>
              <th style={{ width: '200px' }}>Name</th>
              <th style={{ width: '150px' }}>Contact</th>
              <th style={{ width: '120px' }}>Role</th>
              <th style={{ width: '120px' }}>Visibility</th>
              <th style={{ width: '180px' }}>Actions</th>
              <th style={{ width: '180px' }}>Completion</th>
            </tr>
          </thead>
          <tbody>
            {paginatedUsers.map((user) => (
              <tr key={user.id}>
                <td className="email-cell">{user.email}</td>
                <td className="name-cell">{user.name || '-'}</td>
                <td className="contact-cell">{user.phone || '-'}</td>
                <td className="role-cell">
                  {user.isAdmin && <span className="role admin">Admin</span>}
                  {user.isModerator && <span className="role mod">Mod</span>}
                  {!user.isAdmin && !user.isModerator && (
                    <span className="role user">User</span>
                  )}
                </td>
                <td className="visibility-cell">
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      checked={user.publicProfile || false}
                      onChange={() =>
                        toggleUserVisibility(user.id, user.publicProfile)
                      }
                    />
                    <span className="toggle-slider"></span>
                  </label>
                </td>
                <td className="actions-cell">
                  <div className="action-buttons">
                    <button
                      onClick={() =>
                        handleToggleAdminStatus(user.id, user.isAdmin)
                      }
                      className={`icon-btn ${user.isAdmin ? 'active' : ''}`}
                      data-tooltip={
                        user.isAdmin
                          ? 'Remove Admin Access'
                          : 'Grant Admin Access'
                      }
                    >
                      <i className="fa-solid fa-shield"></i>
                    </button>
                    <button
                      onClick={() =>
                        handleToggleModeratorStatus(user.id, user.isModerator)
                      }
                      className={`icon-btn ${user.isModerator ? 'active' : ''}`}
                      data-tooltip={
                        user.isModerator
                          ? 'Remove Moderator Access'
                          : 'Grant Moderator Access'
                      }
                    >
                      <i className="fa-solid fa-user-gear"></i>
                    </button>
                    <button
                      onClick={() =>
                        window.open(`/profile/${user.id}`, '_blank')
                      }
                      className="icon-btn view"
                      data-tooltip="View Public Profile"
                    >
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </button>
                    <button
                      onClick={() => handleDeleteUser(user.id)}
                      className="icon-btn delete"
                      data-tooltip="Delete User"
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  </div>
                </td>
                <td className="completion-cell">
                  <div className="completion-wrapper">
                    <div
                      className="completion-bar"
                      style={{
                        width: `${calculateProfileCompletion(user)}%`,
                        background: `${
                          calculateProfileCompletion(user) < 50
                            ? '#ff4444'
                            : calculateProfileCompletion(user) < 80
                            ? '#ffd700'
                            : '#4CAF50'
                        }`,
                      }}
                    />
                    <span className="completion-text">
                      {calculateProfileCompletion(user)}%
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
          className="page-btn"
        >
          <i className="fa-solid fa-angles-left"></i>
        </button>
        <button
          onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
          disabled={currentPage === 1}
          className="page-btn"
        >
          <i className="fa-solid fa-angle-left"></i>
        </button>
        <span className="page-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(totalPages, prev + 1))
          }
          disabled={currentPage === totalPages}
          className="page-btn"
        >
          <i className="fa-solid fa-angle-right"></i>
        </button>
        <button
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
          className="page-btn"
        >
          <i className="fa-solid fa-angles-right"></i>
        </button>
      </div>

      {showAddUserForm && (
        <div className="modal-overlay" onClick={handleCloseAddUserForm}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>Add New User</h3>
              <button className="modal-close" onClick={handleCloseAddUserForm}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>

            <form onSubmit={handleAddUser} className="add-user-form">
              <div className="form-section">
                <div className="form-group">
                  <label htmlFor="email">
                    <i className="fa-solid fa-envelope"></i>
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={newUser.email}
                    onChange={(e) =>
                      setNewUser({ ...newUser, email: e.target.value })
                    }
                    placeholder="Enter user's email"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">
                    <i className="fa-solid fa-lock"></i>
                    Password
                  </label>
                  <div className="password-input">
                    <input
                      type="password"
                      id="password"
                      value={newUser.password}
                      onChange={(e) =>
                        setNewUser({ ...newUser, password: e.target.value })
                      }
                      placeholder="Enter secure password"
                      required
                    />
                    <span className="password-toggle">
                      <i className="fa-solid fa-eye"></i>
                    </span>
                  </div>
                  <small className="input-help">
                    Password must be at least 8 characters long
                  </small>
                </div>
              </div>

              <div className="form-section">
                <div className="role-assignment">
                  <label className="role-section-label">
                    <i className="fa-solid fa-user-shield"></i>
                    Initial Role (Optional)
                  </label>
                  <div className="role-checkboxes">
                    <label className="role-checkbox">
                      <input
                        type="checkbox"
                        checked={newUser.isAdmin}
                        onChange={(e) =>
                          setNewUser({ ...newUser, isAdmin: e.target.checked })
                        }
                      />
                      <span className="checkbox-label">
                        <i className="fa-solid fa-shield"></i>
                        Admin
                      </span>
                    </label>
                    <label className="role-checkbox">
                      <input
                        type="checkbox"
                        checked={newUser.isModerator}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isModerator: e.target.checked,
                          })
                        }
                      />
                      <span className="checkbox-label">
                        <i className="fa-solid fa-user-gear"></i>
                        Moderator
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button type="submit" className="btn-submit">
                  <i className="fa-solid fa-user-plus"></i>
                  Add User
                </button>
                <button
                  type="button"
                  className="btn-cancel"
                  onClick={handleCloseAddUserForm}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserManagement;
