import React from 'react';
import { Helmet } from 'react-helmet';

function DefaultSEO() {
  return (
    <Helmet defaultTitle="OECSL - Online Entrepreneurs Club Sri Lanka">
      {/* Primary Meta Tags */}
      <title>OECSL - Online Entrepreneurs Club Sri Lanka</title>
      <meta
        name="title"
        content="OECSL - Online Entrepreneurs Club Sri Lanka"
      />
      <meta
        name="description"
        content="Sri Lanka's largest digital entrepreneurship community. Join 175,000+ members in tech innovation, freelancing, and digital transformation."
      />
      <meta
        name="keywords"
        content="OECSL, Online Entrepreneurs Club, Sri Lanka, Digital Entrepreneurship, Freelancing, Tech Community, Digital Innovation, Tech Education, Sri Lankan Startups"
      />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://oecsl.com/" />
      <meta
        property="og:title"
        content="OECSL - Online Entrepreneurs Club Sri Lanka"
      />
      <meta
        property="og:description"
        content="Sri Lanka's largest digital entrepreneurship community. Join 175,000+ members in tech innovation, freelancing, and digital transformation."
      />
      <meta property="og:image" content="https://oecsl.com/og-image.jpg" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://oecsl.com/" />
      <meta
        property="twitter:title"
        content="OECSL - Online Entrepreneurs Club Sri Lanka"
      />
      <meta
        property="twitter:description"
        content="Sri Lanka's largest digital entrepreneurship community. Join 175,000+ members in tech innovation, freelancing, and digital transformation."
      />
      <meta property="twitter:image" content="https://oecsl.com/og-image.jpg" />
    </Helmet>
  );
}

export default DefaultSEO;
