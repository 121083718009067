import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
} from 'firebase/firestore';
import { FaCheck, FaTimes, FaCopy, FaEnvelope } from 'react-icons/fa';
import '../styles/InvitationRequestManagement.css';

function InvitationRequestManagement() {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequests, setSelectedRequests] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [activeTab, setActiveTab] = useState('pending');

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      const requestsRef = collection(db, 'invitationRequests');
      const q = query(requestsRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);

      const requestsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
      }));

      setRequests(requestsData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching requests:', error);
      setLoading(false);
    }
  };

  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    // Optional: Show a toast notification
    alert('Email copied to clipboard!');
  };

  const handleCopySelectedEmails = () => {
    const selectedEmails = requests
      .filter((request) => selectedRequests.has(request.id))
      .map((request) => request.email)
      .join(', ');

    navigator.clipboard.writeText(selectedEmails);
    alert('Selected emails copied to clipboard!');
  };

  const toggleSelectRequest = (requestId) => {
    const newSelected = new Set(selectedRequests);
    if (newSelected.has(requestId)) {
      newSelected.delete(requestId);
    } else {
      newSelected.add(requestId);
    }
    setSelectedRequests(newSelected);
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRequests(new Set());
    } else {
      setSelectedRequests(new Set(requests.map((r) => r.id)));
    }
    setSelectAll(!selectAll);
  };

  const handleBulkAction = async (action) => {
    if (selectedRequests.size === 0) {
      alert('Please select at least one request');
      return;
    }

    const confirmMessage = `Are you sure you want to ${action} ${selectedRequests.size} request(s)?`;
    if (!window.confirm(confirmMessage)) return;

    try {
      await Promise.all(
        Array.from(selectedRequests).map(async (requestId) => {
          const requestRef = doc(db, 'invitationRequests', requestId);
          if (action === 'approve') {
            await updateDoc(requestRef, { status: 'approved' });
          } else if (action === 'reject') {
            await updateDoc(requestRef, { status: 'rejected' });
          }
        }),
      );

      fetchRequests();
      setSelectedRequests(new Set());
      setSelectAll(false);
    } catch (error) {
      console.error(`Error performing bulk ${action}:`, error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleSingleAction = async (requestId, action) => {
    try {
      const requestRef = doc(db, 'invitationRequests', requestId);
      await updateDoc(requestRef, {
        status: action === 'approve' ? 'approved' : 'rejected',
      });
      fetchRequests();
    } catch (error) {
      console.error(`Error ${action}ing request:`, error);
      alert('An error occurred. Please try again.');
    }
  };

  // Filter requests based on active tab
  const filteredRequests = requests.filter(
    (request) =>
      request.status === activeTab ||
      (activeTab === 'pending' && !request.status),
  );

  if (loading) {
    return <div className="loading-state">Loading requests...</div>;
  }

  return (
    <div className="invitation-management">
      <div className="management-header">
        <h2>Invitation Requests</h2>

        <div className="status-tabs">
          <button
            className={`tab-btn ${activeTab === 'pending' ? 'active' : ''}`}
            onClick={() => setActiveTab('pending')}
          >
            Pending
          </button>
          <button
            className={`tab-btn ${activeTab === 'approved' ? 'active' : ''}`}
            onClick={() => setActiveTab('approved')}
          >
            Approved
          </button>
          <button
            className={`tab-btn ${activeTab === 'rejected' ? 'active' : ''}`}
            onClick={() => setActiveTab('rejected')}
          >
            Rejected
          </button>
        </div>

        <div className="bulk-actions">
          <label className="select-all">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={toggleSelectAll}
            />
            Select All
          </label>

          {selectedRequests.size > 0 && (
            <>
              <button
                className="bulk-action-btn approve"
                onClick={() => handleBulkAction('approve')}
              >
                <FaCheck /> Approve Selected ({selectedRequests.size})
              </button>
              <button
                className="bulk-action-btn reject"
                onClick={() => handleBulkAction('reject')}
              >
                <FaTimes /> Reject Selected ({selectedRequests.size})
              </button>
              <button
                className="bulk-action-btn copy"
                onClick={handleCopySelectedEmails}
              >
                <FaCopy /> Copy Emails
              </button>
            </>
          )}
        </div>
      </div>

      <div className="requests-list">
        {filteredRequests.map((request) => (
          <div key={request.id} className="request-card">
            <input
              type="checkbox"
              checked={selectedRequests.has(request.id)}
              onChange={() => toggleSelectRequest(request.id)}
            />
            <span className="request-name">{request.name || 'No Name'}</span>
            <span>{request.email}</span>
            <span>{request.status || 'pending'}</span>
            <span>{request.createdAt.toLocaleDateString()}</span>
            <div className="request-actions">
              {request.status !== 'approved' && (
                <button
                  className="action-btn approve"
                  onClick={() => handleSingleAction(request.id, 'approve')}
                  title="Approve Request"
                >
                  <FaCheck />
                </button>
              )}
              {request.status !== 'rejected' && (
                <button
                  className="action-btn reject"
                  onClick={() => handleSingleAction(request.id, 'reject')}
                  title="Reject Request"
                >
                  <FaTimes />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InvitationRequestManagement;
