import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Events from './components/Events';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Login from './components/Login';
import Register from './components/Register';
import Admin from './components/Admin';
import UserDashboard from './components/UserDashboard';
import PublicProfile from './components/PublicProfile';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import TestInvite from './components/TestInvite';
import SkillExploration from './components/SkillExploration';
import DefaultSEO from './components/DefaultSEO';
import { HelmetProvider } from 'react-helmet-async';
import SkillExplorationSEO from './components/SkillExplorationSEO';
import Store from './components/Store';
import Marketplace from './components/Marketplace';
import AdminRoute from './components/AdminRoute';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <AuthProvider>
          <div className="App">
            <DefaultSEO />
            <Header />
            <main className="main-content">
              <div className="container">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/events" element={<Events />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route
                    path="/admin/*"
                    element={
                      <AdminRoute>
                        <Admin />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/user-dashboard"
                    element={
                      <PrivateRoute>
                        <UserDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/profile/:userId" element={<PublicProfile />} />
                  <Route path="/test-invite" element={<TestInvite />} />
                  <Route
                    path="/skills"
                    element={
                      <>
                        <SkillExplorationSEO />
                        <SkillExploration />
                      </>
                    }
                  />
                  <Route path="/store" element={<Store />} />
                  <Route path="/marketplace" element={<Marketplace />} />
                  <Route
                    path="/marketplace/requests"
                    element={<Marketplace initialView="requests" />}
                  />
                </Routes>
              </div>
            </main>
            <Footer />
          </div>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
