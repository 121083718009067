import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import {
  collection,
  addDoc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';

function LoginPromptModal({ onClose }) {
  const navigate = useNavigate();

  const handleSignup = async () => {
    try {
      // Generate a code that matches our format (8 chars, uppercase)
      const generateInviteCode = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let code = '';
        for (let i = 0; i < 8; i++) {
          code += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return code;
      };

      const code = generateInviteCode();

      // Create invitation request with code already included
      await addDoc(collection(db, 'invitationCodes'), {
        code: code,
        maxUses: 5,
        usedCount: 0,
        status: 'active',
        source: 'marketplace',
        createdAt: serverTimestamp(),
      });

      // Redirect to register with the code
      navigate(`/register?code=${code}`);
    } catch (error) {
      console.error('Error generating invitation:', error);
      // Fallback to regular signup if something goes wrong
      navigate('/register');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="login-prompt-modal">
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Sign in Required</h2>
        <p>Please sign in or create an account to contact service providers.</p>
        <div className="button-group">
          <button
            className="btn btn-primary"
            onClick={() => navigate('/login')}
          >
            Sign In
          </button>
          <button className="btn btn-secondary" onClick={handleSignup}>
            Create Account
          </button>
        </div>
      </div>
    </div>
  );
}

LoginPromptModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default LoginPromptModal;
