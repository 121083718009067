import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FaTimes,
  FaEnvelope,
  FaStar,
  FaHeart,
  FaBookmark,
  FaShareAlt,
  FaClock,
  FaCheckCircle,
  FaTag,
} from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import LoginPromptModal from './LoginPromptModal';

function ServiceViewModal({ service, onClose, onContact, isOwner }) {
  const { currentUser } = useAuth();
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: service.title,
          text: service.description,
          url: window.location.href,
        });
      } else {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const handleContact = () => {
    if (!currentUser) {
      setShowLoginPrompt(true);
      return;
    }
    onContact(service);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal service-view-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header">
          <h2>{service.title}</h2>
          <button className="close-btn" onClick={onClose}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body">
          {/* Service Image */}
          <div className="service-image-container">
            <img
              src={
                service.imageUrl ||
                'https://www.callcentrehelper.com/images/stories/2016/05/available1-760.jpg'
              }
              alt={service.imageUrl ? service.title : 'Service Available'}
              className="service-image"
            />
            {service.category && (
              <div className="service-category">
                <FaTag /> {service.category}
              </div>
            )}
          </div>

          {/* Provider Info */}
          <div className="provider-info">
            <img
              src={
                service.profilePictureUrl ||
                `https://ui-avatars.com/api/?name=${encodeURIComponent(
                  service.fullName || 'Anonymous',
                )}&background=0D8ABC&color=fff`
              }
              alt={service.fullName || 'Service Provider'}
              className="provider-avatar"
            />
            <div className="provider-details">
              <span className="provider-name">
                {service.fullName || 'Anonymous'}
              </span>
              <div className="provider-stats">
                {service.completedOrders === '0' ? (
                  <span className="new-badge">New</span>
                ) : (
                  <>
                    <span className="rating">
                      <FaStar /> {service.rating || '0.0'}
                    </span>
                    <span className="reviews">
                      ({service.reviewCount || '0'} reviews)
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Service Description */}
          <div className="service-description">
            <h3>Description</h3>
            <p>{service.description}</p>
          </div>

          {/* Service Features */}
          {service.features && service.features.length > 0 && (
            <div className="service-features">
              <h3>What&apos;s Included</h3>
              <ul>
                {service.features.map((feature, index) => (
                  <li key={index}>
                    <FaCheckCircle /> {feature}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Service Details */}
          <div className="service-metrics">
            <div className="metric">
              <span className="label">Delivery Time</span>
              <span className="value">
                <FaClock /> {service.deliveryTime || '7'} days
              </span>
            </div>
            <div className="metric">
              <span className="label">Response Time</span>
              <span className="value">
                <FaClock /> {service.responseTime || '24'}h
              </span>
            </div>
            <div className="metric">
              <span className="label">Completed Orders</span>
              <span className="value">
                <FaCheckCircle /> {service.completedOrders || '0'}
              </span>
            </div>
          </div>

          {/* Requirements */}
          {service.requirements && (
            <div className="service-requirements">
              <h3>Requirements</h3>
              <p>{service.requirements}</p>
            </div>
          )}

          {/* Tags */}
          {service.tags && service.tags.length > 0 && (
            <div className="service-tags">
              <h3>Tags</h3>
              <div className="tags-container">
                {service.tags.map((tag, index) => (
                  <span key={index} className="tag">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Price Section */}
          <div className="price-section">
            <div className="price-header">
              <span className="price-label">Price</span>
            </div>
            <span className="price-amount">
              {service.pricingType === 'on_request'
                ? 'Price on request'
                : `$${
                    typeof service.price === 'number'
                      ? service.price.toFixed(2)
                      : service.price
                  }`}
            </span>
          </div>
        </div>

        <div className="modal-footer">
          {!isOwner && (
            <>
              <div className="social-actions">
                <button className="action-btn" title="Add to Favorites">
                  <FaHeart />
                </button>
                <button className="action-btn" title="Save for Later">
                  <FaBookmark />
                </button>
                <button
                  className="action-btn"
                  onClick={handleShare}
                  title="Share"
                >
                  <FaShareAlt />
                </button>
              </div>
              <button
                className="btn btn-primary"
                onClick={handleContact}
                title="Contact provider"
              >
                <FaEnvelope /> Contact Provider
              </button>
            </>
          )}

          <button className="btn btn-secondary" onClick={onClose}>
            Close
          </button>
        </div>

        {showLoginPrompt && (
          <LoginPromptModal onClose={() => setShowLoginPrompt(false)} />
        )}
      </div>
    </div>
  );
}

ServiceViewModal.propTypes = {
  service: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    category: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pricingType: PropTypes.string,
    fullName: PropTypes.string,
    profilePictureUrl: PropTypes.string,
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reviewCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deliveryTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    responseTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    completedOrders: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    features: PropTypes.arrayOf(PropTypes.string),
    requirements: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onContact: PropTypes.func.isRequired,
  isOwner: PropTypes.bool,
};

export default ServiceViewModal;
