import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import ServiceCard from './ServiceCard';
import ServiceFormModal from './ServiceFormModal';
import { FaEdit, FaToggleOn, FaToggleOff, FaTrash } from 'react-icons/fa';

function MyServices() {
  const { currentUser } = useAuth();
  const [services, setServices] = useState([]);
  const [stats, setStats] = useState({
    active: 0,
    inactive: 0,
    totalRequests: 0,
    pendingRequests: 0,
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingService, setEditingService] = useState(null);

  useEffect(() => {
    fetchMyServices();
    fetchServiceStats();
  }, [currentUser]);

  const fetchMyServices = async () => {
    if (!currentUser) return;

    const q = query(
      collection(db, 'services'),
      where('userId', '==', currentUser.uid),
    );
    const snapshot = await getDocs(q);
    setServices(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  const fetchServiceStats = async () => {
    if (!currentUser) return;

    const requestsQuery = query(
      collection(db, 'serviceRequests'),
      where('sellerId', '==', currentUser.uid),
    );
    const requestsSnapshot = await getDocs(requestsQuery);

    setStats({
      active: services.filter((s) => s.isActive).length,
      inactive: services.filter((s) => !s.isActive).length,
      totalRequests: requestsSnapshot.size,
      pendingRequests: requestsSnapshot.docs.filter(
        (d) => d.data().status === 'pending',
      ).length,
    });
  };

  const handleEdit = (service) => {
    setEditingService(service);
    setShowEditModal(true);
  };

  const handleToggleActive = async (service) => {
    if (!currentUser) return;

    try {
      await updateDoc(doc(db, 'services', service.id), {
        isActive: !service.isActive,
        updatedAt: serverTimestamp(),
      });
      fetchMyServices();
    } catch (error) {
      console.error('Error toggling service:', error);
    }
  };

  const handleDelete = async (id) => {
    if (!currentUser) return;

    try {
      await updateDoc(doc(db, 'services', id), {
        isActive: false,
        updatedAt: serverTimestamp(),
      });
      fetchMyServices();
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };

  return (
    <div className="my-services">
      <div className="stats-panel">
        <div className="stat-card">
          <h3>Active Services</h3>
          <p>{stats.active}</p>
        </div>
        <div className="stat-card">
          <h3>Pending Requests</h3>
          <p>{stats.pendingRequests}</p>
        </div>
        <div className="stat-card">
          <h3>Total Requests</h3>
          <p>{stats.totalRequests}</p>
        </div>
      </div>

      <div className="my-services-grid">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            <div className="service-image-container">
              <img
                src={
                  service.imageUrl ||
                  'https://www.callcentrehelper.com/images/stories/2016/05/available1-760.jpg'
                }
                alt={service.title}
                className="service-image"
              />
              <div className="service-status">
                {service.isActive ? 'Active' : 'Inactive'}
              </div>
            </div>

            <div className="card-content">
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">
                {service.description?.substring(0, 100)}...
              </p>

              <div className="service-price">
                <span className="price-label">Price:</span>
                <span className="price-amount">
                  {service.pricingType === 'on_request'
                    ? 'Price on request'
                    : `$${service.price}`}
                </span>
              </div>

              <div className="service-actions">
                <button
                  className="btn btn-primary"
                  onClick={() => handleEdit(service)}
                  title="Edit service"
                >
                  <FaEdit /> Edit
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => handleToggleActive(service)}
                  title={
                    service.isActive ? 'Deactivate service' : 'Activate service'
                  }
                >
                  {service.isActive ? <FaToggleOn /> : <FaToggleOff />}
                  {service.isActive ? 'Deactivate' : 'Activate'}
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(service.id)}
                  title="Delete service"
                >
                  <FaTrash /> Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showEditModal && (
        <ServiceFormModal
          service={editingService}
          onSubmit={async (formData) => {
            try {
              await updateDoc(doc(db, 'services', editingService.id), {
                ...formData,
                updatedAt: serverTimestamp(),
              });
              setShowEditModal(false);
              fetchMyServices();
            } catch (error) {
              console.error('Error updating service:', error);
            }
          }}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </div>
  );
}

export default MyServices;
