import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import {
  FaEdit,
  FaTrash,
  FaShoppingCart,
  FaTimes,
  FaPlus,
  FaBoxOpen,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import { db } from '../firebase';
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
  getDoc,
} from 'firebase/firestore';
import '../styles/Store.css';
import { Link } from 'react-router-dom';

// Update ProductCard to only use props for handling clicks
const ProductCard = ({
  product,
  onImageClick,
  onAddToCartClick,
  isAdmin,
  onEdit,
  onDelete,
}) => {
  const { currentUser } = useAuth();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const description = product.description || '';
  const shortDescription =
    description.length > 100
      ? `${description.substring(0, 100)}...`
      : description;

  // Get the first image or use a placeholder
  const mainImage = product.images?.[0] || '/placeholder-image.jpg';

  // Helper function to determine button text
  const getButtonText = () => {
    if (product.isOutOfStock) return 'Out of Stock';
    if (product.isMembersOnly && !currentUser) return 'Members Only';
    return 'Add to Cart';
  };

  return (
    <div
      className={`product-card ${product.isOutOfStock ? 'out-of-stock' : ''}`}
    >
      <div className="product-images" onClick={() => onImageClick(product)}>
        <img src={mainImage} alt={product.name} />
        {product.images?.length > 1 && (
          <div className="image-dots">
            {product.images.map((_, index) => (
              <span key={index} className="dot" />
            ))}
          </div>
        )}
      </div>
      <h3>{product.name}</h3>
      <p onClick={() => setShowFullDescription(!showFullDescription)}>
        {showFullDescription ? description : shortDescription}
        {description.length > 100 && (
          <span className="read-more">
            {showFullDescription ? ' Show less' : ' Read more'}
          </span>
        )}
      </p>
      <div className="price">LKR {product.price}</div>
      <div className="product-card-footer">
        {isAdmin && (
          <div className="admin-actions">
            <button onClick={onEdit}>
              <FaEdit />
            </button>
            <button onClick={onDelete}>
              <FaTrash />
            </button>
          </div>
        )}
        <button
          className="add-to-cart-btn"
          onClick={() => onAddToCartClick(product)}
          disabled={
            (product.isMembersOnly && !currentUser) || product.isOutOfStock
          }
        >
          {getButtonText()}
        </button>
      </div>
    </div>
  );
};

// Update ProductCard PropTypes
ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    images: PropTypes.arrayOf(PropTypes.string),
    variants: PropTypes.shape({
      colors: PropTypes.arrayOf(PropTypes.string),
      sizes: PropTypes.arrayOf(PropTypes.string),
    }),
    isMembersOnly: PropTypes.bool,
    isOutOfStock: PropTypes.bool,
  }).isRequired,
  onImageClick: PropTypes.func.isRequired,
  onAddToCartClick: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

// Move modals outside main component
const ProductModal = ({
  onClose,
  onSubmit,
  productForm,
  setProductForm,
  selectedProduct,
}) => {
  const handleImageChange = (index, value) => {
    const newImages = [...productForm.images];
    newImages[index] = value;
    setProductForm({ ...productForm, images: newImages });
  };

  const removeImageField = (index) => {
    const newImages = productForm.images.filter((_, i) => i !== index);
    setProductForm({ ...productForm, images: newImages });
  };

  // Remove duplicate handleColorChange function and consolidate color handling
  const handleColorChange = (color) => {
    if (!color) return;

    // Prevent duplicates
    if (!productForm.variants.colors.includes(color)) {
      setProductForm({
        ...productForm,
        variants: {
          ...productForm.variants,
          colors: [...productForm.variants.colors, color],
        },
      });
    }
  };

  const removeColor = (colorToRemove) => {
    setProductForm({
      ...productForm,
      variants: {
        ...productForm.variants,
        colors: productForm.variants.colors.filter(
          (color) => color !== colorToRemove,
        ),
      },
    });
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
          <h2>{selectedProduct ? 'Edit Product' : 'Add Product'}</h2>
          <form onSubmit={onSubmit}>
            <input
              type="text"
              placeholder="Product Name"
              value={productForm.name}
              onChange={(e) =>
                setProductForm({ ...productForm, name: e.target.value })
              }
              required
            />

            <select
              value={productForm.category}
              onChange={(e) =>
                setProductForm({ ...productForm, category: e.target.value })
              }
              required
            >
              <option value="">Select Category</option>
              <option value="tshirt">T-Shirt</option>
              <option value="mug">Mug</option>
              <option value="cap">Cap</option>
              <option value="keytag">Key Tag</option>
              <option value="other">Other</option>
            </select>

            {productForm.category === 'tshirt' && (
              <div className="size-options">
                <label>Available Sizes:</label>
                <div className="checkbox-group">
                  {['S', 'M', 'L', 'XL', 'XXL'].map((size) => (
                    <label key={size}>
                      <input
                        type="checkbox"
                        checked={productForm.sizes?.includes(size) || false}
                        onChange={(e) => {
                          const newSizes = e.target.checked
                            ? [...(productForm.sizes || []), size]
                            : (productForm.sizes || []).filter(
                                (s) => s !== size,
                              );
                          setProductForm({ ...productForm, sizes: newSizes });
                        }}
                      />
                      {size}
                    </label>
                  ))}
                </div>
              </div>
            )}

            {/* Multiple Images */}
            <div className="images-section">
              <label>Product Images</label>
              {productForm.images.map((url, index) => (
                <div key={index} className="image-input-group">
                  <input
                    type="url"
                    placeholder="Image URL"
                    value={url}
                    onChange={(e) => handleImageChange(index, e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => removeImageField(index)}
                    className="remove-image"
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
            </div>

            {/* Colors */}
            <div className="colors-section">
              <label>Available Colors</label>
              <div className="color-inputs">
                <input
                  type="text"
                  placeholder="Add a color (e.g., Black)"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleColorChange(e.target.value);
                      e.target.value = '';
                    }
                  }}
                />
                <div className="color-tags">
                  {productForm.variants.colors.map((color) => (
                    <span key={color} className="color-tag">
                      {color}
                      <button type="button" onClick={() => removeColor(color)}>
                        <FaTimes />
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            </div>

            {/* Category-specific details */}
            {productForm.category === 'tshirt' && (
              <div className="product-details">
                <input
                  type="text"
                  placeholder="Material (e.g., 100% Cotton)"
                  value={productForm.details.tshirt.material}
                  onChange={(e) =>
                    setProductForm({
                      ...productForm,
                      details: {
                        ...productForm.details,
                        tshirt: {
                          ...productForm.details.tshirt,
                          material: e.target.value,
                        },
                      },
                    })
                  }
                />
                <input
                  type="text"
                  placeholder="Weight (e.g., 180gsm)"
                  value={productForm.details.tshirt.weight}
                  onChange={(e) =>
                    setProductForm({
                      ...productForm,
                      details: {
                        ...productForm.details,
                        tshirt: {
                          ...productForm.details.tshirt,
                          weight: e.target.value,
                        },
                      },
                    })
                  }
                />
                <select
                  value={productForm.details.tshirt.fit}
                  onChange={(e) =>
                    setProductForm({
                      ...productForm,
                      details: {
                        ...productForm.details,
                        tshirt: {
                          ...productForm.details.tshirt,
                          fit: e.target.value,
                        },
                      },
                    })
                  }
                >
                  <option value="">Select Fit</option>
                  <option value="regular">Regular Fit</option>
                  <option value="slim">Slim Fit</option>
                  <option value="oversized">Oversized</option>
                </select>
              </div>
            )}

            <input
              type="text"
              placeholder="Price"
              value={productForm.price}
              onChange={(e) =>
                setProductForm({ ...productForm, price: e.target.value })
              }
              required
              min="0"
              step="0.01"
            />

            <input
              type="number"
              placeholder="Stock Quantity"
              value={productForm.stock}
              onChange={(e) =>
                setProductForm({ ...productForm, stock: e.target.value })
              }
              required
              min="0"
            />

            <textarea
              placeholder="Description"
              value={productForm.description}
              onChange={(e) =>
                setProductForm({ ...productForm, description: e.target.value })
              }
              required
            />

            <div className="product-options">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={productForm.isMembersOnly}
                  onChange={(e) =>
                    setProductForm({
                      ...productForm,
                      isMembersOnly: e.target.checked,
                    })
                  }
                />
                Members Only
              </label>

              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={productForm.isOutOfStock}
                  onChange={(e) =>
                    setProductForm({
                      ...productForm,
                      isOutOfStock: e.target.checked,
                    })
                  }
                />
                Out of Stock
              </label>
            </div>

            <button type="submit">
              {selectedProduct ? 'Update Product' : 'Add Product'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

ProductModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  productForm: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    variants: PropTypes.shape({
      colors: PropTypes.arrayOf(PropTypes.string).isRequired,
      sizes: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    details: PropTypes.shape({
      tshirt: PropTypes.shape({
        material: PropTypes.string,
        weight: PropTypes.string,
        fit: PropTypes.string,
      }).isRequired,
      mug: PropTypes.shape({
        capacity: PropTypes.string,
        material: PropTypes.string,
        dishwasherSafe: PropTypes.bool,
      }).isRequired,
      cap: PropTypes.shape({
        adjustable: PropTypes.bool,
        material: PropTypes.string,
      }).isRequired,
      keytag: PropTypes.shape({
        material: PropTypes.string,
        length: PropTypes.string,
      }).isRequired,
    }).isRequired,
    stock: PropTypes.string,
    sizes: PropTypes.arrayOf(PropTypes.string),
    isMembersOnly: PropTypes.bool.isRequired,
    isOutOfStock: PropTypes.bool.isRequired,
  }).isRequired,
  setProductForm: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
};

const CartModal = ({ onClose, cart, setCart, onCheckout }) => {
  const { currentUser } = useAuth();
  const [customerDetails, setCustomerDetails] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });

  // Prefill user details when component mounts
  useEffect(() => {
    if (currentUser) {
      // Fetch user profile data from Firestore
      const fetchUserProfile = async () => {
        try {
          const docRef = doc(db, 'users', currentUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setCustomerDetails({
              name: userData.name || '',
              email: currentUser.email || '',
              phone: userData.phone || '',
              address: userData.address || '',
            });
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      };
      fetchUserProfile();
    }
  }, [currentUser]);

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCheckout(customerDetails);
  };

  const removeCartItem = (item) => {
    // Remove specific item based on id, color, and size combination
    setCart(
      cart.filter(
        (cartItem) =>
          !(
            cartItem.id === item.id &&
            cartItem.selectedVariants.color === item.selectedVariants.color &&
            cartItem.selectedVariants.size === item.selectedVariants.size
          ),
      ),
    );
  };

  if (!currentUser) {
    return (
      <div className="modal">
        <div className="modal-content">
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
          <div className="auth-prompt">
            <h2>Sign in to Continue</h2>
            <p>
              Please sign in or create an account to proceed with your purchase.
            </p>
            <div className="auth-buttons">
              <Link to="/login" className="auth-button">
                Sign In
              </Link>
              <Link to="/register" className="auth-button register">
                Create Account
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Shopping Cart</h2>

        {cart.length === 0 ? (
          <p>Your cart is empty</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="cart-items">
              {cart.map((item, index) => (
                <div
                  key={`${item.id}-${item.selectedVariants.color}-${item.selectedVariants.size}-${index}`}
                  className="cart-item"
                >
                  <div className="cart-item-details">
                    <h4>{item.name}</h4>
                    <div className="item-variants">
                      {item.selectedVariants?.color && (
                        <span className="variant">
                          Color: {item.selectedVariants.color}
                        </span>
                      )}
                      {item.selectedVariants?.size && (
                        <span className="variant">
                          Size: {item.selectedVariants.size}
                        </span>
                      )}
                      <span className="variant">Qty: {item.quantity || 1}</span>
                    </div>
                  </div>
                  <span className="item-price">LKR {item.price}</span>
                  <button
                    type="button"
                    className="remove-item"
                    onClick={() => removeCartItem(item)}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
              <div className="cart-total">
                Total: LKR{' '}
                {cart.reduce(
                  (sum, item) =>
                    sum + Number(item.price) * (item.quantity || 1),
                  0,
                )}
              </div>
            </div>

            <div className="customer-details">
              <h3>Delivery Details</h3>
              <div className="form-group">
                <label htmlFor="name">Full Name *</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={customerDetails.name}
                  onChange={handleDetailsChange}
                  required
                  placeholder="Enter your full name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email Address *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={customerDetails.email}
                  onChange={handleDetailsChange}
                  required
                  placeholder="Enter your email address"
                />
              </div>

              <div className="form-group">
                <label htmlFor="phone">Contact Number *</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={customerDetails.phone}
                  onChange={handleDetailsChange}
                  required
                  placeholder="Enter your contact number"
                />
              </div>

              <div className="form-group">
                <label htmlFor="address">Delivery Address *</label>
                <textarea
                  id="address"
                  name="address"
                  value={customerDetails.address}
                  onChange={handleDetailsChange}
                  required
                  placeholder="Enter your delivery address"
                  rows="3"
                />
              </div>
            </div>

            <button
              type="submit"
              className="checkout-button"
              disabled={cart.length === 0}
            >
              Proceed to Checkout
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

CartModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }),
  ).isRequired,
  setCart: PropTypes.func.isRequired,
  onCheckout: PropTypes.func.isRequired,
};

const OrdersModal = ({ onClose, orders }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
    }).format(date);
  };

  const getOrderStatus = (status) => {
    const statusColors = {
      pending: '#ffd700', // Gold
      processing: '#1e90ff', // Blue
      shipped: '#32cd32', // Green
      delivered: '#00f0ff', // Cyan
      cancelled: '#ff4444', // Red
    };

    return (
      <span className="order-status" style={{ color: statusColors[status] }}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    );
  };

  if (selectedOrder) {
    return (
      <div className="modal">
        <div className="modal-content order-details-modal">
          <button
            className="close-button"
            onClick={() => setSelectedOrder(null)}
          >
            <FaChevronLeft />
          </button>
          <h2>Order Details</h2>

          <div className="order-info">
            <div className="order-header">
              <div>
                <h3>Order #{selectedOrder.id.slice(0, 8)}</h3>
                <p className="order-date">
                  {formatDate(selectedOrder.createdAt)}
                </p>
              </div>
              <div className="order-status-badge">
                {getOrderStatus(selectedOrder.status)}
              </div>
            </div>

            <div className="order-section">
              <h4>Items</h4>
              <div className="order-items">
                {selectedOrder.items.map((item, index) => (
                  <div key={index} className="order-item">
                    <div className="item-info">
                      <h5>{item.name}</h5>
                      <div className="item-details">
                        {item.selectedVariants?.color && (
                          <span>Color: {item.selectedVariants.color}</span>
                        )}
                        {item.selectedVariants?.size && (
                          <span>Size: {item.selectedVariants.size}</span>
                        )}
                        <span>Qty: {item.quantity}</span>
                      </div>
                    </div>
                    <div className="item-price">
                      LKR {item.price * item.quantity}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="order-section">
              <h4>Delivery Details</h4>
              <div className="delivery-details">
                <p>
                  <strong>Name:</strong> {selectedOrder.customerDetails.name}
                </p>
                <p>
                  <strong>Email:</strong> {selectedOrder.customerDetails.email}
                </p>
                <p>
                  <strong>Phone:</strong> {selectedOrder.customerDetails.phone}
                </p>
                <p>
                  <strong>Address:</strong>{' '}
                  {selectedOrder.customerDetails.address}
                </p>
              </div>
            </div>

            <div className="order-summary">
              <div className="summary-row">
                <span>Subtotal</span>
                <span>LKR {selectedOrder.total}</span>
              </div>
              <div className="summary-row">
                <span>Delivery</span>
                <span>Free</span>
              </div>
              <div className="summary-row total">
                <span>Total</span>
                <span>LKR {selectedOrder.total}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Your Orders</h2>

        {orders.length === 0 ? (
          <p className="no-orders">No orders found</p>
        ) : (
          <div className="orders-list">
            {orders.map((order) => (
              <div
                key={order.id}
                className="order-card"
                onClick={() => setSelectedOrder(order)}
              >
                <div className="order-card-header">
                  <h3>Order #{order.id.slice(0, 8)}</h3>
                  {getOrderStatus(order.status)}
                </div>
                <div className="order-card-content">
                  <div className="order-summary-brief">
                    <p>{order.items.length} item(s)</p>
                    <p>LKR {order.total}</p>
                  </div>
                  <p className="order-date">{formatDate(order.createdAt)}</p>
                </div>
                <button className="view-details-btn">
                  View Details <FaChevronRight />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

OrdersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

// Update ImageSliderModal component
const ImageSliderModal = ({ product, images, onClose, onAddToCart }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedVariants, setSelectedVariants] = useState({
    color: '',
    size: '',
    quantity: 1,
  });

  const handlePrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const handleAddToCart = () => {
    if (
      (!product.variants?.colors?.length || selectedVariants.color) &&
      (!product.sizes?.length || selectedVariants.size)
    ) {
      onAddToCart([
        {
          ...product,
          selectedVariants: {
            color: selectedVariants.color,
            size: selectedVariants.size,
          },
          quantity: selectedVariants.quantity,
        },
      ]);
      onClose();
    } else {
      alert('Please select all required options');
    }
  };

  return (
    <div className="modal product-modal" onClick={onClose}>
      <div
        className="modal-content product-view"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>

        <div className="product-view-container">
          {/* Left side - Image slider */}
          <div className="product-images-section">
            <div className="image-slider">
              <button className="nav-button prev" onClick={handlePrev}>
                <FaChevronLeft />
              </button>
              <img
                src={images[currentIndex]}
                alt={`Slide ${currentIndex + 1}`}
              />
              <button className="nav-button next" onClick={handleNext}>
                <FaChevronRight />
              </button>
            </div>
            <div className="image-thumbnails">
              {images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Thumbnail ${index + 1}`}
                  className={currentIndex === index ? 'active' : ''}
                  onClick={() => setCurrentIndex(index)}
                />
              ))}
            </div>
          </div>

          {/* Right side - Product details */}
          <div className="product-details-section">
            <h2>{product.name}</h2>
            <div className="product-price">LKR {product.price}</div>

            <div className="product-description">
              <h3>Description</h3>
              <p>{product.description}</p>
            </div>

            <div className="product-variations">
              {product.variants?.colors?.length > 0 && (
                <div className="variation-section">
                  <h3>Available Colors</h3>
                  <div className="variation-options">
                    {product.variants.colors.map((color) => (
                      <div
                        key={color}
                        className={`variation-option ${
                          selectedVariants.color === color ? 'selected' : ''
                        }`}
                        onClick={() =>
                          setSelectedVariants((prev) => ({ ...prev, color }))
                        }
                      >
                        <span
                          className="color-swatch"
                          style={{
                            backgroundColor: color.toLowerCase(),
                            border:
                              color.toLowerCase() === 'white'
                                ? '1px solid #ddd'
                                : 'none',
                          }}
                        />
                        <span className="variation-label">{color}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {product.sizes?.length > 0 && (
                <div className="variation-section">
                  <h3>Available Sizes</h3>
                  <div className="variation-options">
                    {product.sizes.map((size) => (
                      <div
                        key={size}
                        className={`variation-option size-chip ${
                          selectedVariants.size === size ? 'selected' : ''
                        }`}
                        onClick={() =>
                          setSelectedVariants((prev) => ({ ...prev, size }))
                        }
                      >
                        {size}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="quantity-selector">
                <h3>Quantity</h3>
                <div className="quantity-controls">
                  <button
                    onClick={() =>
                      setSelectedVariants((prev) => ({
                        ...prev,
                        quantity: Math.max(1, prev.quantity - 1),
                      }))
                    }
                  >
                    -
                  </button>
                  <input
                    type="number"
                    min="1"
                    value={selectedVariants.quantity}
                    onChange={(e) =>
                      setSelectedVariants((prev) => ({
                        ...prev,
                        quantity: parseInt(e.target.value) || 1,
                      }))
                    }
                  />
                  <button
                    onClick={() =>
                      setSelectedVariants((prev) => ({
                        ...prev,
                        quantity: prev.quantity + 1,
                      }))
                    }
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            {product.category === 'tshirt' && (
              <div className="specifications">
                <h3>Specifications</h3>
                <ul>
                  <li>
                    <strong>Material:</strong>{' '}
                    {product.details?.tshirt?.material}
                  </li>
                  <li>
                    <strong>Weight:</strong> {product.details?.tshirt?.weight}
                  </li>
                  <li>
                    <strong>Fit:</strong> {product.details?.tshirt?.fit}
                  </li>
                </ul>
              </div>
            )}

            <button
              className="add-to-cart-btn"
              onClick={handleAddToCart}
              disabled={
                (product.variants?.colors?.length && !selectedVariants.color) ||
                (product.sizes?.length && !selectedVariants.size)
              }
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Update PropTypes
ImageSliderModal.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    description: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    details: PropTypes.object,
    variants: PropTypes.shape({
      colors: PropTypes.arrayOf(PropTypes.string),
    }),
    sizes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onAddToCart: PropTypes.func.isRequired,
};

// Update AddToCartModal component
const AddToCartModal = ({ product, onClose, onAdd }) => {
  const [selectedVariant, setSelectedVariant] = useState({
    color: '',
    size: '',
    quantity: 1,
  });

  // Get available sizes based on product category
  const getAvailableSizes = () => {
    if (product.category === 'tshirt') {
      return product.sizes || [];
    }
    return product.variants.sizes || [];
  };

  const isValid =
    (!product.variants.colors.length || selectedVariant.color) &&
    (!getAvailableSizes().length || selectedVariant.size) &&
    selectedVariant.quantity > 0;

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Add to Cart - {product.name}</h2>

        <div className="variant-selection">
          {/* Size Selection */}
          {getAvailableSizes().length > 0 && (
            <div className="variant-field">
              <label>Size:</label>
              <select
                value={selectedVariant.size}
                onChange={(e) =>
                  setSelectedVariant({
                    ...selectedVariant,
                    size: e.target.value,
                  })
                }
                required
              >
                <option value="">Select Size</option>
                {getAvailableSizes().map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Color Selection */}
          {product.variants.colors.length > 0 && (
            <div className="variant-field">
              <label>Color:</label>
              <select
                value={selectedVariant.color}
                onChange={(e) =>
                  setSelectedVariant({
                    ...selectedVariant,
                    color: e.target.value,
                  })
                }
                required
              >
                <option value="">Select Color</option>
                {product.variants.colors.map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Quantity Selection */}
          <div className="variant-field">
            <label>Quantity:</label>
            <input
              type="number"
              min="1"
              value={selectedVariant.quantity}
              onChange={(e) =>
                setSelectedVariant({
                  ...selectedVariant,
                  quantity: parseInt(e.target.value) || 1,
                })
              }
              required
            />
          </div>
        </div>

        {/* Show selected options summary */}
        <div className="selection-summary">
          <p>Selected Options:</p>
          <ul>
            {selectedVariant.size && <li>Size: {selectedVariant.size}</li>}
            {selectedVariant.color && <li>Color: {selectedVariant.color}</li>}
            <li>Quantity: {selectedVariant.quantity}</li>
          </ul>
        </div>

        <div className="modal-actions">
          <button
            onClick={() =>
              onAdd([
                {
                  ...product,
                  selectedVariants: {
                    color: selectedVariant.color,
                    size: selectedVariant.size,
                  },
                  quantity: selectedVariant.quantity,
                },
              ])
            }
            disabled={!isValid}
            className="add-to-cart-button"
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

// Update AddToCartModal PropTypes
AddToCartModal.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    variants: PropTypes.shape({
      colors: PropTypes.arrayOf(PropTypes.string),
      sizes: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    sizes: PropTypes.arrayOf(PropTypes.string), // For t-shirt specific sizes
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

function Store() {
  const { currentUser } = useAuth();
  const [products, setProducts] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [cart, setCart] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [orders, setOrders] = useState([]);
  const [productForm, setProductForm] = useState({
    name: '',
    category: '',
    price: '',
    description: '',
    images: [''], // Array of image URLs
    variants: {
      colors: [], // Available colors
      sizes: [], // Available sizes
    },
    details: {
      tshirt: {
        material: '',
        weight: '',
        fit: '',
      },
      mug: {
        capacity: '',
        material: '',
        dishwasherSafe: false,
      },
      cap: {
        adjustable: false,
        material: '',
      },
      keytag: {
        material: '',
        length: '',
      },
    },
    stock: '0', // Initialize with '0' instead of empty string
    color: '', // Add this field
    sizes: [], // Add this field
    isMembersOnly: false,
    isOutOfStock: false, // Add this field
  });
  const [showImageSlider, setShowImageSlider] = useState(false);
  const [showVariationPicker, setShowVariationPicker] = useState(false);
  const [selectedProductForModal, setSelectedProductForModal] = useState(null);

  // Fetch products once on mount
  useEffect(() => {
    fetchProducts();
  }, []);

  // Fetch orders when user changes
  useEffect(() => {
    if (currentUser) fetchOrders();
  }, [currentUser]);

  // Update handleProductSubmit to use the resetForm
  const handleProductSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedProduct) {
        await updateDoc(doc(db, 'products', selectedProduct.id), productForm);
      } else {
        await addDoc(collection(db, 'products'), productForm);
      }
      setShowAddModal(false);
      setSelectedProduct(null);
      setProductForm({
        name: '',
        category: '',
        price: '',
        description: '',
        images: [''],
        variants: {
          colors: [],
          sizes: [],
        },
        details: {
          tshirt: {
            material: '',
            weight: '',
            fit: '',
          },
          mug: {
            capacity: '',
            material: '',
            dishwasherSafe: false,
          },
          cap: {
            adjustable: false,
            material: '',
          },
          keytag: {
            material: '',
            length: '',
          },
        },
        stock: '0',
        color: '',
        sizes: [],
        isMembersOnly: false,
        isOutOfStock: false,
      });
      fetchProducts();
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };

  // Update setSelectedProduct to properly initialize form
  useEffect(() => {
    if (selectedProduct) {
      setProductForm({
        ...productForm,
        ...selectedProduct,
        variants: {
          ...productForm.variants,
          ...selectedProduct.variants,
        },
        details: {
          ...productForm.details,
          ...selectedProduct.details,
        },
      });
    } else {
      setProductForm(productForm);
    }
  }, [selectedProduct]);

  const fetchProducts = async () => {
    try {
      const snapshot = await getDocs(collection(db, 'products'));
      const productsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        // Ensure required fields exist
        images: doc.data().images || [],
        variants: doc.data().variants || { colors: [], sizes: [] },
        description: doc.data().description || '',
        isMembersOnly: doc.data().isMembersOnly || false,
        isOutOfStock: doc.data().isOutOfStock || false,
      }));
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchOrders = async () => {
    try {
      const q = query(
        collection(db, 'orders'),
        where('userId', '==', currentUser.uid),
      );
      const snapshot = await getDocs(q);
      setOrders(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleDeleteProduct = async (productId) => {
    if (!window.confirm('Are you sure you want to delete this product?'))
      return;
    try {
      await deleteDoc(doc(db, 'products', productId));
      fetchProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleCheckout = async (customerDetails) => {
    if (!currentUser) {
      alert('Please login to checkout');
      return;
    }

    try {
      const orderData = {
        userId: currentUser.uid,
        items: cart.map((item) => ({
          id: item.id,
          name: item.name,
          price: item.price,
          category: item.category,
          selectedVariants: item.selectedVariants,
          quantity: item.quantity || 1,
          details: item.details || {}, // Preserve product-specific details
        })),
        total: cart.reduce(
          (sum, item) => sum + Number(item.price) * (item.quantity || 1),
          0,
        ),
        status: 'pending',
        createdAt: new Date(),
        customerDetails: customerDetails,
      };

      await addDoc(collection(db, 'orders'), orderData);
      setCart([]);
      setShowCartModal(false);
      alert('Order placed successfully!');
    } catch (error) {
      console.error('Error creating order:', error);
      alert('Failed to place order. Please try again.');
    }
  };

  // Handler for opening image slider
  const handleImageSliderOpen = (product) => {
    setSelectedProductForModal(product);
    setShowImageSlider(true);
  };

  // Handler for opening variation picker
  const handleVariationPickerOpen = (product) => {
    setSelectedProductForModal(product);
    setShowVariationPicker(true);
  };

  // In the Store component, add this new handler
  const handleDirectAddToCart = (items) => {
    setCart([...cart, ...items]);
    setShowImageSlider(false);
    setSelectedProductForModal(null);
  };

  return (
    <div className="store-page">
      <section className="hero section">
        <div className="container">
          <h1 className="section-title">Store</h1>
          <p className="subtitle">
            Explore our collection of products and member-exclusive items
          </p>
        </div>
      </section>

      <div className="store-content container">
        <div className="store-actions">
          {currentUser?.isAdmin && (
            <button onClick={() => setShowAddModal(true)}>
              <FaPlus /> Add Product
            </button>
          )}
          <button onClick={() => setShowCartModal(true)}>
            <FaShoppingCart /> Cart ({cart.length})
          </button>
          {currentUser && (
            <button onClick={() => setShowOrderModal(true)}>
              <FaBoxOpen /> My Orders
            </button>
          )}
        </div>

        <div className="products-grid">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              onImageClick={handleImageSliderOpen}
              onAddToCartClick={handleVariationPickerOpen}
              isAdmin={currentUser?.isAdmin}
              onEdit={() => {
                setSelectedProduct(product);
                setShowAddModal(true);
              }}
              onDelete={() => handleDeleteProduct(product.id)}
            />
          ))}
        </div>

        {/* Move all modals outside of the products grid */}
        <div className="modals-container">
          {showAddModal && (
            <ProductModal
              onClose={() => setShowAddModal(false)}
              onSubmit={handleProductSubmit}
              productForm={productForm}
              setProductForm={setProductForm}
              selectedProduct={selectedProduct}
            />
          )}
          {showCartModal && (
            <CartModal
              onClose={() => setShowCartModal(false)}
              cart={cart}
              setCart={setCart}
              onCheckout={handleCheckout}
            />
          )}
          {showOrderModal && (
            <OrdersModal
              onClose={() => setShowOrderModal(false)}
              orders={orders}
            />
          )}
          {showImageSlider && selectedProductForModal && (
            <ImageSliderModal
              product={selectedProductForModal}
              images={selectedProductForModal.images}
              onClose={() => {
                setShowImageSlider(false);
                setSelectedProductForModal(null);
              }}
              onAddToCart={handleDirectAddToCart}
            />
          )}
          {showVariationPicker && selectedProductForModal && (
            <AddToCartModal
              product={selectedProductForModal}
              onClose={() => {
                setShowVariationPicker(false);
                setSelectedProductForModal(null);
              }}
              onAdd={(items) => {
                setCart([...cart, ...items]);
                setShowVariationPicker(false);
                setSelectedProductForModal(null);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Store;
