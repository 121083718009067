import React from 'react';
import PropTypes from 'prop-types';
import '../styles/profileWizardMascot.css';
import { FaUser } from 'react-icons/fa';

const messages = [
  "Let's make your profile shine!",
  "You're doing great! Keep it up!",
  'Almost there! Your profile is looking awesome!',
  "Wow, you're a superstar! This profile is amazing!",
];

const ProfileWizardMascot = ({ step, profilePicture }) => {
  return (
    <div className="profile-wizard-mascot">
      <div className="mascot-content">
        {profilePicture ? (
          <img src={profilePicture} alt="Profile" className="profile-image" />
        ) : (
          <div className="profile-placeholder">
            <FaUser size={40} />
          </div>
        )}
        <p>{messages[Math.min(step, messages.length - 1)]}</p>
      </div>
    </div>
  );
};

ProfileWizardMascot.propTypes = {
  step: PropTypes.number.isRequired,
  profilePicture: PropTypes.string,
};

ProfileWizardMascot.defaultProps = {
  profilePicture: '',
};

export default ProfileWizardMascot;
