export const calculateProfileCompletion = (profile) => {
  if (!profile) return 0;

  const requiredFields = [
    'fullName',
    'email',
    'phone',
    'location',
    'skills',
    'profilePictureUrl',
    'tagline',
    'bio',
    'hourlyRate',
    'availabilityStatus',
    'yearsOfExperience',
    'languages',
    'workExperience',
    'portfolioItems',
    'countriesServed',
  ];

  const fieldWeights = {
    fullName: 10,
    email: 10,
    phone: 5,
    location: 5,
    skills: 15,
    profilePictureUrl: 10,
    tagline: 5,
    bio: 10,
    hourlyRate: 5,
    availabilityStatus: 5,
    yearsOfExperience: 5,
    languages: 5,
    workExperience: 5,
    portfolioItems: 5,
    countriesServed: 5,
  };

  let totalScore = 0;
  let totalWeight = 0;

  requiredFields.forEach((field) => {
    const weight = fieldWeights[field];
    totalWeight += weight;

    if (field === 'skills') {
      const hasSkills =
        profile[field] && Object.values(profile[field]).flat().length > 0;
      if (hasSkills) totalScore += weight;
    } else if (
      field === 'languages' ||
      field === 'workExperience' ||
      field === 'portfolioItems' ||
      field === 'countriesServed'
    ) {
      const hasArray = profile[field] && profile[field].length > 0;
      if (hasArray) totalScore += weight;
    } else if (profile[field] && profile[field].toString().trim() !== '') {
      totalScore += weight;
    }
  });

  return Math.round((totalScore / totalWeight) * 100);
};

export const getProfileCompletionDetails = (userProfile) => {
  const completionPercentage = calculateProfileCompletion(userProfile);

  // Return detailed breakdown of completion
  return {
    percentage: completionPercentage,
    nextSteps: getNextSteps(userProfile),
    completedSections: getCompletedSections(userProfile),
    remainingSections: getRemainingSections(userProfile),
  };
};

const getNextSteps = (userProfile) => {
  const steps = [];

  if (!userProfile.fullName || !userProfile.bio) {
    steps.push('Complete your personal details');
  }
  if (!userProfile.email || !userProfile.phone) {
    steps.push('Add contact information');
  }
  if (!userProfile.skills || Object.keys(userProfile.skills).length === 0) {
    steps.push('Add your skills and expertise');
  }
  if (!userProfile.workExperience || userProfile.workExperience.length === 0) {
    steps.push('Add work experience');
  }
  if (!userProfile.portfolioItems || userProfile.portfolioItems.length === 0) {
    steps.push('Add portfolio items');
  }

  return steps;
};

const getCompletedSections = (userProfile) => {
  const completed = [];

  if (userProfile.fullName && userProfile.bio) {
    completed.push('Personal Details');
  }
  if (userProfile.email && userProfile.phone) {
    completed.push('Contact Information');
  }
  if (userProfile.skills && Object.keys(userProfile.skills).length > 0) {
    completed.push('Skills & Expertise');
  }
  if (userProfile.workExperience && userProfile.workExperience.length > 0) {
    completed.push('Work Experience');
  }
  if (userProfile.portfolioItems && userProfile.portfolioItems.length > 0) {
    completed.push('Portfolio');
  }

  return completed;
};

const getRemainingSections = (userProfile) => {
  const allSections = [
    'Personal Details',
    'Contact Information',
    'Skills & Expertise',
    'Work Experience',
    'Portfolio',
    'Testimonials',
    'Languages',
    'Countries Served',
    'Awards & Certifications',
  ];

  const completed = getCompletedSections(userProfile);
  return allSections.filter((section) => !completed.includes(section));
};
