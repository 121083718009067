import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { db } from '../firebase';
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  updateDoc,
  doc as firestoreDoc,
  deleteDoc,
  addDoc,
  serverTimestamp,
  getDoc,
  getDocs,
  limit,
} from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import {
  FaTimes,
  FaTrash,
  FaCheck,
  FaEnvelope,
  FaClock,
  FaReply,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import '../styles/MessagesDrawer.css';

function MessagesDrawer({ isOpen, onClose }) {
  const { currentUser } = useAuth();
  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedThread, setExpandedThread] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [messageLimit, setMessageLimit] = useState(5);

  useEffect(() => {
    if (!currentUser) {
      setLoading(false);
      return;
    }

    // Query for message threads
    const threadsQuery = query(
      collection(db, 'messages'),
      where('participants', 'array-contains', currentUser.uid),
      orderBy('lastMessageAt', 'desc'),
    );

    const unsubscribe = onSnapshot(threadsQuery, async (snapshot) => {
      try {
        const threadsList = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const threadData = doc.data();
            if (!currentUser) return null;

            const otherUserId = threadData.participants.find(
              (id) => id !== currentUser.uid,
            );

            // Get other user's data
            const userDoc = await getDoc(
              firestoreDoc(db, 'users', otherUserId),
            );
            const userData = userDoc.data() || {};

            // Get thread messages for preview
            const messagesQuery = query(
              collection(db, 'messages'),
              where('threadId', '==', doc.id),
              orderBy('createdAt', 'desc'),
              limit(1),
            );
            const messagesSnap = await getDocs(messagesQuery);
            const lastMessage = messagesSnap.docs[0]?.data();

            return {
              id: doc.id,
              ...threadData,
              title: threadData.serviceName || 'New Conversation',
              lastMessage: lastMessage?.message || '',
              lastMessageAt: lastMessage?.createdAt || threadData.createdAt,
              otherUser: {
                id: otherUserId,
                name:
                  userData.displayName || userData.fullName || 'Unknown User',
                avatar:
                  userData.photoURL ||
                  userData.profilePictureUrl ||
                  `https://ui-avatars.com/api/?name=${encodeURIComponent(
                    userData.displayName || userData.fullName || 'U',
                  )}&background=0D8ABC&color=fff`,
              },
              unread:
                !threadData.read && threadData.lastSenderId !== currentUser.uid,
            };
          }),
        );

        // Filter out any null values from the map
        setThreads(threadsList.filter(Boolean));
      } catch (error) {
        console.error('Error loading threads:', error);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [currentUser]);

  // Load thread messages when expanded
  useEffect(() => {
    if (!expandedThread || !currentUser) return;

    const messagesQuery = query(
      collection(db, 'messages'),
      where('threadId', '==', expandedThread),
      where('isThreadMessage', '==', true),
      orderBy('createdAt', 'desc'),
      limit(messageLimit),
    );

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const messages = snapshot.docs
        .map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            messageId: doc.id,
            message: data.message,
            senderId: data.senderId,
            createdAt: data.createdAt?.toDate
              ? data.createdAt
              : new Date(data.createdAt),
            isSender: data.senderId === currentUser.uid,
            // Add a unique identifier combining thread and message IDs
            uniqueKey: `${expandedThread}-${doc.id}`,
          };
        })
        .reverse();

      setThreads((prev) =>
        prev.map((thread) => {
          if (thread.id === expandedThread) {
            // Deduplicate messages using uniqueKey
            const uniqueMessages = messages.reduce((acc, message) => {
              acc[message.uniqueKey] = message;
              return acc;
            }, {});

            return {
              ...thread,
              messages: Object.values(uniqueMessages),
              lastMessage:
                messages[messages.length - 1]?.message || thread.lastMessage,
              lastMessageAt:
                messages[messages.length - 1]?.createdAt ||
                thread.lastMessageAt,
              lastSenderId:
                messages[messages.length - 1]?.senderId || thread.lastSenderId,
            };
          }
          return thread;
        }),
      );
    });

    return () => unsubscribe();
  }, [expandedThread, currentUser, messageLimit]);

  const handleReply = async (threadId) => {
    if (!replyText.trim() || !currentUser) return;

    try {
      const thread = threads.find((t) => t.id === threadId);
      if (!thread) return;

      const tempId = `temp-${Date.now()}`; // Create temporary ID
      const messageData = {
        threadId,
        message: replyText,
        senderId: currentUser.uid,
        receiverId: thread.otherUser.id,
        createdAt: serverTimestamp(),
        read: false,
        participants: thread.participants,
        isThreadMessage: true,
      };

      // Optimistic update with temporary ID
      setThreads((prevThreads) =>
        prevThreads.map((t) => {
          if (t.id === threadId) {
            const optimisticMessage = {
              id: tempId,
              messageId: tempId,
              ...messageData,
              createdAt: new Date(),
              isSender: true,
              uniqueKey: `${threadId}-${tempId}`,
            };

            return {
              ...t,
              messages: [...(t.messages || []), optimisticMessage],
              lastMessage: replyText,
              lastMessageAt: new Date(),
              lastSenderId: currentUser.uid,
            };
          }
          return t;
        }),
      );

      // Clear reply text early for better UX
      setReplyText('');

      // Add message to Firestore
      const messageRef = await addDoc(collection(db, 'messages'), messageData);

      // Update thread's last message
      await updateDoc(firestoreDoc(db, 'messages', threadId), {
        lastMessageAt: serverTimestamp(),
        lastMessage: replyText,
        lastSenderId: currentUser.uid,
        read: false,
      });
    } catch (error) {
      console.error('Error sending reply:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';

    // Convert to Date object if it's a Firestore timestamp
    const date = timestamp?.toDate ? timestamp.toDate() : new Date(timestamp);
    const now = new Date();
    const diff = now - date;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days === 0) {
      // For today, show time
      return date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });
    } else if (days === 1) {
      return 'Yesterday';
    } else if (days < 7) {
      return `${days} days ago`;
    } else {
      return date.toLocaleDateString();
    }
  };

  const formatTimeAgo = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp?.toDate ? timestamp.toDate() : new Date(timestamp);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return 'just now';
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)}h ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)}d ago`;
    return formatDate(timestamp);
  };

  const handleMessagesScroll = (e) => {
    const container = e.target;
    if (container.scrollTop === 0) {
      setMessageLimit((prev) => prev + 5);
    }
  };

  return (
    <div className={`messages-drawer ${isOpen ? 'open' : ''}`}>
      <div className="messages-header">
        <h3>
          {expandedThread && currentUser
            ? threads.find((t) => t.id === expandedThread)?.otherUser.name ||
              'Messages'
            : 'Messages'}
        </h3>
        {expandedThread ? (
          <button
            onClick={() => setExpandedThread(null)}
            className="close-button"
          >
            <FaChevronDown />
          </button>
        ) : (
          <button onClick={onClose} className="close-button">
            <FaTimes />
          </button>
        )}
      </div>

      {!currentUser ? (
        <div className="no-messages">
          <p>Please sign in to view messages</p>
        </div>
      ) : loading ? (
        <div className="loading">Loading messages...</div>
      ) : (
        <div
          className={`messages-list ${expandedThread ? 'has-expanded' : ''}`}
        >
          {threads.map((thread) => (
            <div
              key={thread.id}
              className={`thread-item ${thread.unread ? 'unread' : ''} ${
                expandedThread === thread.id ? 'expanded' : ''
              }`}
            >
              <div
                className="thread-header"
                onClick={() =>
                  setExpandedThread(
                    thread.id === expandedThread ? null : thread.id,
                  )
                }
              >
                <div className="user-info">
                  <img
                    src={thread.otherUser.avatar}
                    alt=""
                    className="user-avatar"
                  />
                  <div className="thread-details">
                    <h4>{thread.otherUser.name}</h4>
                    <div className="thread-title">{thread.title}</div>
                    <div className="thread-preview">
                      <span className="message-direction">
                        {thread.lastSenderId === currentUser.uid ? (
                          <FaReply className="sent-icon" />
                        ) : (
                          <FaEnvelope className="received-icon" />
                        )}
                      </span>
                      <span className="last-message">{thread.lastMessage}</span>
                      <span
                        className="message-time"
                        title={formatDate(thread.lastMessageAt)}
                      >
                        {formatTimeAgo(thread.lastMessageAt)}
                      </span>
                    </div>
                  </div>
                </div>
                {thread.unread && <div className="unread-indicator" />}
                {expandedThread === thread.id && (
                  <FaChevronDown className="expand-icon" />
                )}
              </div>

              {expandedThread === thread.id && (
                <>
                  <div
                    className="messages-container"
                    onScroll={handleMessagesScroll}
                  >
                    {thread.messages?.length > 0 ? (
                      <div className="messages-wrapper">
                        {thread.messages.map((message) => (
                          <div
                            key={`${thread.id}-${message.messageId}`}
                            className={`message ${
                              message.isSender ? 'sent' : 'received'
                            }`}
                          >
                            <p>{message.message}</p>
                            <span className="message-time">
                              {formatTimeAgo(message.createdAt)}
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="no-thread-messages">
                        <p>No messages yet</p>
                        <small>
                          Start the conversation by sending a message
                        </small>
                      </div>
                    )}
                  </div>

                  <div
                    className="reply-form"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <textarea
                      value={replyText}
                      onChange={(e) => setReplyText(e.target.value)}
                      placeholder="Type your reply..."
                      rows="2"
                    />
                    <button
                      className="btn btn-primary"
                      onClick={() => handleReply(thread.id)}
                      disabled={!replyText.trim()}
                    >
                      <FaReply /> Reply
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}

          {threads.length === 0 && (
            <div className="no-messages">
              <p>No messages yet</p>
              <small>Your conversations will appear here</small>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

MessagesDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MessagesDrawer;
