import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaTimes, FaCamera, FaImage } from 'react-icons/fa';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import html2canvas from 'html2canvas';
import '../styles/feedbackForm.css';
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from 'firebase/storage';

const FEEDBACK_TYPES = [
  { id: 'feedback', label: 'General Feedback' },
  { id: 'suggestion', label: 'Suggestion' },
  { id: 'feature', label: 'Feature Request' },
  { id: 'error', label: 'Error Report' },
];

const RATING_AREAS = [
  { id: 'usability', label: 'Ease of Use' },
  { id: 'design', label: 'Design & Layout' },
  { id: 'performance', label: 'Performance' },
  { id: 'features', label: 'Features & Functionality' },
];

function FeedbackForm({ onClose }) {
  const [type, setType] = useState('feedback');
  const [message, setMessage] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [ratings, setRatings] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const fileInputRef = useRef();
  const { currentUser } = useAuth();

  const captureScreen = async () => {
    try {
      // Hide the feedback modal before capture
      const modalElement = document.querySelector('.modal-overlay');
      if (modalElement) {
        modalElement.style.visibility = 'hidden';
      }

      const canvas = await html2canvas(document.body);
      const screenshot = canvas.toDataURL('image/png');

      // Show the modal again
      if (modalElement) {
        modalElement.style.visibility = 'visible';
      }

      setScreenshot(screenshot);
    } catch (error) {
      console.error('Error capturing screenshot:', error);
      alert('Failed to capture screenshot');
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setScreenshot(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent event bubbling
    if (!message.trim()) {
      alert('Please provide feedback message');
      return;
    }

    setIsSubmitting(true);
    try {
      let screenshotUrl = null;

      // Upload screenshot if exists
      if (screenshot) {
        const storage = getStorage();
        const screenshotRef = ref(
          storage,
          `feedback-screenshots/${Date.now()}.png`,
        );
        await uploadString(screenshotRef, screenshot, 'data_url');
        screenshotUrl = await getDownloadURL(screenshotRef);
      }

      const feedbackRef = collection(db, 'feedback');
      await addDoc(feedbackRef, {
        type,
        message: message.trim(),
        screenshot: screenshotUrl,
        ratings,
        userId: currentUser?.uid || 'anonymous',
        userEmail: currentUser?.email || 'anonymous',
        createdAt: serverTimestamp(),
        url: window.location.href,
        userAgent: navigator.userAgent,
        status: 'new',
      });

      setShowSuccess(true);
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRatingChange = (area, value) => {
    setRatings((prev) => ({
      ...prev,
      [area]: value,
    }));
  };

  useEffect(() => {
    // Prevent body scroll when modal opens
    document.body.classList.add('modal-open');

    return () => {
      // Re-enable body scroll when modal closes
      document.body.classList.remove('modal-open');
    };
  }, []);

  if (showSuccess) {
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div
          className="feedback-modal success"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="success-message">
            <h2>Thank You!</h2>
            <p>Your feedback has been submitted successfully.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="feedback-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>

        <form onSubmit={handleSubmit} className="feedback-form">
          <h2>Share Your Feedback</h2>

          <div className="form-group">
            <label>Type of Feedback</label>
            <select value={type} onChange={(e) => setType(e.target.value)}>
              {FEEDBACK_TYPES.map(({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Your Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Tell us what you think..."
              rows="4"
              required
            />
          </div>

          <div className="ratings-section">
            <label>Rate Your Experience</label>
            <div className="ratings-grid">
              {RATING_AREAS.map(({ id, label }) => (
                <div key={id} className="rating-item">
                  <span>{label}</span>
                  <div className="star-rating">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <button
                        key={star}
                        type="button"
                        className={`star ${
                          ratings[id] >= star ? 'active' : ''
                        }`}
                        onClick={() => handleRatingChange(id, star)}
                      >
                        ★
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="screenshot-section">
            <label>Add Screenshot</label>
            <div className="screenshot-buttons">
              <button type="button" onClick={captureScreen}>
                <FaCamera /> Capture Screen
              </button>
              <button
                type="button"
                onClick={() => fileInputRef.current.click()}
              >
                <FaImage /> Upload Image
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                accept="image/*"
                style={{ display: 'none' }}
              />
            </div>
            {screenshot && (
              <div className="screenshot-preview">
                <img src={screenshot} alt="Screenshot" />
                <button type="button" onClick={() => setScreenshot(null)}>
                  Remove
                </button>
              </div>
            )}
          </div>

          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
          </button>
        </form>
      </div>
    </div>
  );
}

FeedbackForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FeedbackForm;
